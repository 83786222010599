
import { isMobile, isTablet } from 'react-device-detect';

import { FooterModuleContainer } from "../StyledComponents/AppStyledComponents";

const Footer = () => {
    return (
        <FooterModuleContainer>
            <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    ©{new Date().getFullYear()} MarketReader, Inc.
                <div className="h-4 mx-3 block border-color border-r border-white">

                </div>
                &nbsp;&nbsp;<a target="_blank" rel="noreferrer" href="https://app.marketreader.com/MarketReader+TOS+-+Mar312023.pdf">Terms of Service</a>
            </div>
            {(!isMobile || isTablet) ?
                <div
                    title="Content on this platform is generated and summarized by automated systems and is not intended to be used as investment nor financial advice."
                    style={{ width: 'calc(100% - 230px)', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                    &nbsp;&nbsp;|&nbsp;&nbsp;Content on this platform is generated and summarized by automated systems and is not intended to be used as investment nor financial advice.
                </div> :
                null
            }
        </FooterModuleContainer>
    );
}
export default Footer;