import { useContext } from "react";
import ModalLayout from "../../Global/Layouts/Modal/ModalLayout";
import WatchlistViewer from './components/WatchlistViewer';
import { StateContext } from "../../Controllers/contexts/StateContext";
import { UndoProvider } from "../../Controllers/contexts/UndoContext";
import PortfolioManager from "./components/PortfolioManager";


const Modals = () => {
    const {
        setShowPortfolioManager,
        setShowWatchlistViewer,
        showPortfolioManager,
        showWatchlistViewer,
    } = useContext(StateContext);

    return (
        <>
            {showWatchlistViewer &&
                <ModalLayout onClose={setShowWatchlistViewer} width={'fit-content'} >
                    <UndoProvider>
                        <WatchlistViewer/>
                    </UndoProvider>
                </ModalLayout>
            }
            {showPortfolioManager &&
                <ModalLayout onClose={setShowPortfolioManager} width={'fit-content'} >
                    <UndoProvider>
                        <PortfolioManager/>
                    </UndoProvider>
                </ModalLayout>
            }
        </>
    )
};

export default Modals;
