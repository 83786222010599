import round from "./round";

const getAveragePeriodReturn = (data) => {
    if (!data || data?.length === 0) return 0;

    const totalReturn = data?.reduce((acc, obj) => acc + obj.period_return, 0);

    return round(totalReturn / data?.length * 100, 1);
};

export default getAveragePeriodReturn;
