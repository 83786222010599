import { useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

import { BlankSpacer } from '../../../Global/StyledComponents/AppStyledComponents';
import {
    FormWrapper,
    SubmitButton,
    Form,
    Heading,
    Input,
    StyledLink,
    WarningText,
} from '../../../Global/StyledComponents/UserFormStyledComponents';
import supabase from '../../../Controllers/services/supabaseClient';


const LoginForm = ({
    setCustomerProfile,
    setIsAuthenticated,
    setRefreshed,
    setSession,
    setSubscription,
    setUser
}) => {
    const [error, setError] = useState(null);
    const [loginCredentials, setLoginCredentials] = useState({
        email: '',
        password: '',
    });

    const navigate = useNavigate();

    useEffect(() => {
        const getUser = async () => {
            const { data, error } = await supabase.auth.getSession();
            const session = data.session;
            const user = session?.user || undefined; 
            if (!user) {
                setIsAuthenticated(false);
                navigate('/login' + window.location.search);
                return;
            }
            setUser(user);
            setSession(session);
            
            
            if (user?.role === 'authenticated') {
                const profileRes = await supabase.from('profiles').select('*');
                const { billing_email } = profileRes.data[0];

                const profile = await supabase.functions.invoke('customerProfile', {
                    body: {
                        billingEmail: billing_email, 
                        userEmail: user.email,
                    },
                });
                setCustomerProfile(JSON.parse(profile?.data));

                const { accessLevels, subscriptions } = JSON.parse(profile?.data);
                setSubscription(subscriptions[0]);
                
                if (!subscriptions.length) {
                    navigate('/selectPlan')
                }

                if (accessLevels.includes('pro')) {
                    setIsAuthenticated(true);
    
                   navigate(sessionStorage.getItem('previousPath') || '/');
                   sessionStorage.removeItem('previousPath');

                   if (!isMobile || isTablet) {
                       return;
                   } else {
                       window.location.reload(false);
                       return;
                   }
                } else {
                    navigate('/accessDenied', { state: { user: { email: user.email, billingEmail: billing_email } } });
                }

            } else {
                console.log(error);
            }
            setRefreshed(true);
            return;
        };
        getUser();
    }, []);

    const handleLoginCredentialsChange = (e) => {
        setLoginCredentials((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }));
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        supabase.auth.signInWithPassword(loginCredentials)
            .then(async (res) => {
                const { data, error } = res;
                const { session, user } = data;
                setUser(user);
                setSession(session);
                
                if (user?.role === 'authenticated') {
                    const profileRes = await supabase.from('profiles').select('*');
                    const { billing_email } = profileRes.data[0];

                    const profile = await supabase.functions.invoke('customerProfile', {
                        body: {
                            billingEmail: billing_email, 
                            userEmail: user.email,
                        },
                    });
                    setCustomerProfile(JSON.parse(profile?.data));

                    const { accessLevels, subscriptions } = JSON.parse(profile?.data);
                    
                    if (!subscriptions.length) {
                        navigate('/selectPlan');

                        return;
                    }

                    if (subscriptions[0].status === 'paused' && sessionStorage.getItem('previousPath') === '/claimOffer') {
                        navigate('/claimOffer');
                        sessionStorage.removeItem('previousPath');
                        return;
                    }

                    if (accessLevels.includes('pro')) {
                        setIsAuthenticated(true);
    
                        navigate(sessionStorage.getItem('previousPath') || '/');
                        sessionStorage.removeItem('previousPath');

                        if (!isMobile || isTablet) {
                            return;
                        } else {
                            window.location.reload(false);
                            return;
                        }
                    } else {
                        navigate('/accessDenied', { state: { user: { email: user.email, billingEmail: billing_email } } });

                        return;
                    }

                } else {
                    console.log(error);
                    setError(error);
                }
        });
    };

    return (
        <FormWrapper isMobile={isMobile && !isTablet}>
            <Heading style={{ marginTop: 0 }}>Sign In to Your Account</Heading>
            <Form onSubmit={handleSubmit}>
                <Input className='login'
                    autoComplete='email'
                    id='email'
                    type="email"
                    value={loginCredentials.email}
                    onChange={handleLoginCredentialsChange}
                    placeholder='Email'
                    style={{ marginBottom: '5px' }}
                />
                <Input className='login'
                    id='password'
                    type="password"
                    value={loginCredentials.password}
                    onChange={handleLoginCredentialsChange}
                    placeholder='Password'
                />
                {error && <WarningText> Invalid email or password. Please try again.</WarningText>}
                <BlankSpacer/>
                <SubmitButton type="submit">Sign In</SubmitButton>
                
                <StyledLink to="/forgotPassword" >
                    <p>Forgot Password?</p>
                </StyledLink>
            </Form>
        </FormWrapper>
    );
};

export default LoginForm;
