import { useEffect, useState } from 'react';
import { StyledButton, StyledLabel, TextArea } from '../StyledComponents/UserFormStyledComponents';

const TickerInputArea = ({ handleSubmit, setTickerArray, tickerArray }) => {
  const [values, setValues] = useState([]);

  useEffect(() => {
    setValues(tickerArray);
  }, [tickerArray]);

  const handleClick = () => {
    handleSubmit(values);
    setValues([]);
  };

  const handleInput = (e) => {
    const inputData = e.target.value;
    setValues(inputData)
    const inputItems = inputData.split(/[\s,]+/);
    setTickerArray(inputItems);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text');
    const pasteItems = pasteData.split(/\r\n|\r|\n|\t|,| /).filter(item => item !== '');
    setValues(values + ' ' + pasteItems.join(', '));
    setTickerArray([...new Set(values.filter(item => item !== '').concat(pasteItems))]);
  };

  return (
    <div>
      <StyledLabel>
        Paste/Type Asset Tickers:
      </StyledLabel>
      <TextArea onInput={handleInput} onPaste={handlePaste} value={values} placeholder="Paste or type your items here..." />
      <StyledButton disabled={!values.length} onClick={handleClick}>
        Add Assets
      </StyledButton>
    </div>
  );
};

export default TickerInputArea;
