const checkAndUpdateHistory = (dataObj, newValue) => {
    const data = dataObj;
    // const timestamps = Object.keys(dataObj).map(key => parseInt(key));
    const newTimestamp = Date.now();

    data[newTimestamp] = newValue;
    // if (timestamps.length < 5) {
    // } else {
    //     timestamps.sort((a, b) => a - b);

    //     const oldestTimestamp = timestamps[0];
    //     delete data[oldestTimestamp];

    //     data[newTimestamp] = newValue;
    // }

    return data;
};

export default checkAndUpdateHistory;
