import styled from 'styled-components';
import { AppColors } from '../../StyledComponents/Graphics';
import { checkAndUpdateHistory, hexToRgba } from '../../../Controllers/utils';
import { StyledButton, StyledLabel, SubmitButton } from '../../StyledComponents/UserFormStyledComponents';
import DropdownMenu from '../../Components/DropdownMenu';
import { LanguageMap, Languages } from '../../constants';
import supabase from '../../../Controllers/services/supabaseClient';
import { useContext, useEffect, useRef, useState } from 'react';
import { StateContext } from '../../../Controllers/contexts/StateContext';
import { AuthContext } from '../../../Controllers/contexts/AuthContext';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { AssetView } from '../InputMenu/InputMenu';
import { StyledValue } from '../InputMenu/InputMenu';
import { MarketReaderLogo } from '../../Logos/Logos';
import { BlankSpacer } from '../../StyledComponents/AppStyledComponents';
import ChatAssist from '../../Components/ChatAssist';

const TranslationContainer = styled.div`
	width: 250px;
	display: flex;
	justify-content: end;
	align-items: end;
`;

const LanguageContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 0 16px;
`;

const HistoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding: 16px 16px 0 16px;
    background-color: ${AppColors.MRWHITE};
    align-items: center;
	width: 100%;
`;

const HistoryItem = styled.div`
    background-color: ${AppColors.MRWHITE};
    border: 1px solid ${hexToRgba(AppColors.MRGREY, 0.25)};
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    width: 75%;
	min-width: 50%;
`;

const InputList = styled.div`
    columns: 3;
    justify-content: space-around;
    background-color: ${hexToRgba(AppColors.MRGREEN, 0.25)};
	border: 1px solid ${AppColors.MRGREEN};
    border-left: 4px solid ${AppColors.MRGREEN};
    border-radius: 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    list-style-type: none;
    padding: 12px;
    padding-left: 20px;
    margin-bottom: 16px;
`;

const MessageBubble = styled.div`
	max-width: 100%;
    min-width: 50px;
    padding: 10px 16px;
    word-wrap: break-word;
    background-color: ${AppColors.MRLIGHTGREY};
    color: ${AppColors.MRBLACK};
    border-radius: 16px;
    position: relative;

	p {
        margin-bottom: 0;
		font-size: 18px;
    }
`;

const Output = styled.div`
    color: ${AppColors.MRBLACK};
	display: flex;

    p {
        margin-bottom: 1em;
		font-size: 18px;
    }
`;

export const StyledList = styled.div`
    padding: 0;
    margin: 0;
	columns: ${({ columns, total }) => columns ? columns : (total / 5) > 2 ? 5 : 3};
`;

export const StyledListItem = styled.div`
    padding: 3px;
    display: flex;
    align-items: center;
`;

const Timestamp = styled.div`
    font-size: 14px;
	font-weight: 600;
    color: ${AppColors.MRGREY};
    text-align: right;
`;

const HistoryReel = ({ history }) => {
	const historyContainerRef = useRef(null)
	const [language, setLanguage] = useState('de');
	const {
		analyzingChat,
		loadingDots,
		logError,
		setAnalyzing,
		setTranslationFailed,
		setHistory,
		setInputs,
		setShowHistory,
	} = useContext(StateContext);

	const handleEditInput = (item) => {
		setShowHistory(false);
		setInputs(item?.inputs);
	};

	const handleSelectLanguage = (e) => {
		const { value } = e.target;
		setLanguage(value);
	};

	const handleTranslate = async ({ historyItem, retry = false }) => {
		setAnalyzing(true);

		const res = await supabase.functions.invoke('translateAnalysis', {
			body: {
				to_be_translated: historyItem.output,
				input_type: 'text',
				languages: [language]
			}
		});

		const { data, error } = res;

		if (error) {

			if (retry) {
				handleTranslate({ historyItem, retry: false });

				return;
			}
			logError(error);
		}

		const { msg, translation} = JSON.parse(data);

		// if (msg) {
		// 	handleTranslate({ historyItem, retry: false });

		// 	return;
		// }

		if (translation) {

			setHistory(prevState => {
				const dataObj = checkAndUpdateHistory(prevState.data, {
					inputs: {
						...historyItem.inputs,
						language: language,
					},
					output: JSON.parse(translation?.message?.text)[language],
				});
	
				// const saveHistory = ({ retry = false }) => {
				// 	supabase.from('user_preferences').upsert({
				// 		...prevState,
				// 		data: dataObj,
				// 		type: 'history',
				// 		user_id: user?.id,
				// 	}).eq('type', 'history').then((res) => {
				// 		if (res?.error) {
				// 			const error = new Error(res?.error?.message);
				// 			logError(error.message, undefined, error);
		
				// 			if (retry) {
				// 				saveHistory({ retry: false });
				// 			}
				// 		}
				// 	});
				// };
	
				// saveHistory({ retry: true });
				setAnalyzing(false);
				
				return ({
					...prevState,
					data: dataObj,
					// type: 'history',
					// user_id: user?.id,
				});
			});
		} else {
			setTranslationFailed(true);
			setAnalyzing(false);
			setTimeout(() => {
                setTranslationFailed(false);
            }, 3000);
		}
	};

	const scrollToBottom = () => {
		historyContainerRef?.current?.scrollTo({
			top: historyContainerRef.current.scrollHeight,
			behavior: 'smooth'
		});
	};

	useEffect(() => {
		setTimeout(scrollToBottom, 100);
	}, [history]);

    return (
        <HistoryContainer ref={historyContainerRef}>
            {Object.entries(history?.data).map(([timestamp, item]) => (
                <HistoryItem key={timestamp}>
					{item?.inputs?.userInput ?
						<InputList style={{ columns: 1 }}>
							<StyledValue style={{ textTransform: 'none', width: '100%', whiteSpace: 'normal' }}>
								{item?.inputs?.userInput}
							</StyledValue>
						</InputList> :
						<InputList chat={item?.inputs?.userInput}>
							{item?.inputs?.macro_analysis?.length ?
								<>
									<StyledLabel>
										Macro Analysis:
									</StyledLabel>
									<StyledList columns={2} >
										{item.inputs?.macro_analysis?.map((item, index) => (
											<StyledListItem key={index}>
												<StyledValue>
													{item}
												</StyledValue>
											</StyledListItem>
										))}
									</StyledList>
								</> : null
							}
							{item?.inputs?.date_start &&
								<StyledLabel style={{ marginTop: 0 }}>
									Start Date:&nbsp;
									<StyledValue>{item?.inputs?.date_start}</StyledValue>
								</StyledLabel>
							}
							{item?.inputs?.date_end &&
								<StyledLabel>
									End Date:&nbsp;
									<StyledValue>{item?.inputs?.date_end}</StyledValue>
								</StyledLabel>
							}
							{item?.inputs?.output_length &&
								<StyledLabel>
									Output Length:&nbsp;
									<StyledValue>{item?.inputs?.output_length}</StyledValue>
								</StyledLabel>
							}
							{item?.inputs?.benchmark_asset &&
								<StyledLabel>
									Benchmark:&nbsp;
									<StyledValue>{item?.inputs?.benchmark_asset}</StyledValue>
								</StyledLabel>
							}
							{item?.inputs?.assets?.length &&
									<StyledLabel>
										Assets:
										<div style={{ padding: 1 }}>
											<AssetView total={item?.inputs?.assets?.length}>
												{item?.inputs?.assets?.map((ticker, index) => (
													<StyledListItem key={index}>
														<StyledValue>
															{ticker}
														</StyledValue>
													</StyledListItem>
												))}
											</AssetView>
										</div>
									</StyledLabel>
							}
						</InputList>
					}
                    <Output>
						{item?.inputs?.userInput &&
							<MarketReaderLogo style={{ marginRight: 10 }} />
						}
						<MessageBubble>
							<Markdown remarkPlugins={[remarkGfm]}>
								{item?.inputs?.userInput ? (
									item?.output || (analyzingChat && loadingDots)
								) : (
									item?.output
								)}
							</Markdown>
							<Timestamp>{new Date(Number(timestamp)).toLocaleString()}</Timestamp>
						</MessageBubble>
					</Output>
					<BlankSpacer />
					{!item?.inputs?.userInput &&
						<LanguageContainer>
							{item?.inputs?.language ?
								<StyledLabel>
									Language:&nbsp;
									<b>{LanguageMap[item?.inputs?.language]}</b>
								</StyledLabel> :
								<TranslationContainer>
									<DropdownMenu
										id='period'
										label={Languages.label}
										handleChange={handleSelectLanguage}
										options={Languages.values}
									/>
									<StyledButton
										onClick={() => handleTranslate({ historyItem: item, retry: true })}
										style={{ margin: '1px 0 1px 10px' }}
									>
										Translate
									</StyledButton>
								</TranslationContainer>
							}
							<SubmitButton
								style={{ marginTop: 'auto', width: 'auto', padding: '10px 20px' }}
								onClick={() => handleEditInput(item)}
							>
								Edit Inputs
							</SubmitButton>
						</LanguageContainer>
					}
                </HistoryItem>
            ))}
			<ChatAssist />
        </HistoryContainer>
    );
};

export default HistoryReel;
