import { useState, useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { LogoutIcon, ProfileIcon, SettingsIcon } from '../../../Icons/Icons';
import { AppColors } from '../../../StyledComponents/Graphics';
import { useNavigate } from 'react-router-dom';
import supabase from '../../../../Controllers/services/supabaseClient';
import { StateContext } from '../../../../Controllers/contexts/StateContext';
import { MenuButton } from '../styledComponents';

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownMenu = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  position: absolute;
  right: 0px;
  top: 55px;
  background-color: ${AppColors.MRWHITE};
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 160px;
`;

const DropdownItem = styled.a`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const IconContainer = styled.div`
    margin-right: 10px;
`;

const ProfileMenu = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const { setIsAuthenticated, setUser } = useContext(StateContext);

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

    const handleLogout = async () => {
        supabase.auth.signOut().then(() => {
            setIsAuthenticated(false);
            setUser({});
            navigate('/login');
        });
    };

  return (
    <DropdownContainer ref={dropdownRef}>
      <MenuButton clicked={showDropdown} onClick={() => setShowDropdown(!showDropdown)}>
        <ProfileIcon />
      </MenuButton>
      <DropdownMenu show={showDropdown}>
        <DropdownItem>
            <IconContainer>
                <SettingsIcon />
            </IconContainer>
            Settings
        </DropdownItem>
        <DropdownItem onClick={handleLogout}>
            <IconContainer>
                <LogoutIcon />
            </IconContainer>
            Logout
        </DropdownItem>
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default ProfileMenu;
