import { Navigate, Outlet } from 'react-router-dom';
import { BlankLoadingScreen } from '../StyledComponents/AppStyledComponents';

const ProtectedRoute = ({
        children,
        isAuthenticated,
        redirectPath = '/login',
        refreshed,
    }) => {
        if (!isAuthenticated && !refreshed) {
            return <BlankLoadingScreen></BlankLoadingScreen>;
        } else if (!isAuthenticated && refreshed) {
            return <Navigate to={redirectPath} replace />;
        }
    
        return children ? children : <Outlet />;
};

  export default ProtectedRoute