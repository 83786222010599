import { useContext, useState, useRef } from "react";
import HistoryReel from "../../Global/Layouts/History/History";
import { StateContext } from "../../Controllers/contexts/StateContext";
import Loading from "../../Global/Components/LoadingSpinner";
import styled from "styled-components";
import { Heading, StyledButton, StyledLabel, SubmitButton } from "../../Global/StyledComponents/UserFormStyledComponents";
import { AppColors } from "../../Global/StyledComponents/Graphics";
import InputMenu from "../../Global/Layouts/InputMenu/InputMenu";
import { UndoProvider } from "../../Controllers/contexts/UndoContext";
import { BlankSpacer } from "../../Global/StyledComponents/AppStyledComponents";
import { DEFAULT_INPUTS } from "../../Global/constants";
import DropdownMenu from "../../Global/Components/DropdownMenu";
import supabase from "../../Controllers/services/supabaseClient";
import SearchDropdown from "../../Global/Components/SearchDropdown";
import { useNavigate } from "react-router-dom";

const CoverContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

const PortfolioContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const HistoryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`;

const PortfolioAnalyzer = () => {
    const {
        analysisFailed,
        analyzing,
        assetsLoading,
        etfsLoading,
        etfAssets,
        etfsWithHoldings,
        history,
        portfolios,
        selectedPortfolio,
        setInputs,
        setShowHistory,
        setSelectedPortfolio,
        setSelectedWatchlist,
        setStartAnalysis,
        showHistory,
        translationFailed,
        setShowPortfolioManager
    } = useContext(StateContext);
    const [clearEtfSearch, setClearEtfSearch] = useState(false);
    const [clearAssetSearch, setClearAssetSearch] = useState(false);
    const menuContainerRef = useRef(null);
    const navigate = useNavigate();

    const handleToggleHistory = () => {
        setShowHistory(prevState => !prevState);
    };

    const handleCreateNewAnalysis = () => {
        setInputs(DEFAULT_INPUTS);
        setSelectedWatchlist(null);
        setSelectedPortfolio(null);
        setStartAnalysis(true);
        setShowHistory(false);
        navigate('/');
    };

    const handleEtfSelect = async (value) => {
        const res = await supabase.rpc('get_etf_tickers', {
            ticker: value,
        });

        const constituents = {};

        res?.data?.forEach(item => constituents[item.symbol] = item.stock_weight);

        setClearAssetSearch(true);
        setSelectedPortfolio(null);
        setInputs(prevState => ({
            ...prevState,
            assets: constituents
        }));
    };

    const handlePortfolioManager = () => {
        setShowPortfolioManager(true);
    };

    const handleSelectPortfolio = (e, value) => {
        const portfolio = value ?? e.target.value;
        setClearEtfSearch(true);
        setClearAssetSearch(true);
        if (!portfolio) {
            setSelectedPortfolio(null);

            setInputs(prevState => ({
                ...prevState,
                assets: [],
            }));

        } else {
            const parsedPortfolio = JSON.parse(portfolio);
            setSelectedPortfolio(parsedPortfolio);

            setInputs(prevState => ({
                ...prevState,
                assets: parsedPortfolio?.data,
            }));
        }
    };

    return (
        <>
            <PortfolioContainer>
                    <div style={{ display: 'flex', position: 'absolute', top: 0, left: 10 }}>
                        {
                        // Object.keys(history?.data).length && !showHistory ? 
                        //     <StyledButton onClick={handleToggleHistory}>
                        //         Back to History
                        //     </StyledButton> :
                            showHistory ?
                                <SubmitButton onClick={handleCreateNewAnalysis} style={{ marginTop: 10, width: 'auto' }}>
                                    Create New
                                </SubmitButton> : null
                        }
                    </div>
                {Object.keys(history?.data).length && showHistory ?
                    <HistoryWrapper >
                        <HistoryReel history={history} />
                        <BlankSpacer />
                    </HistoryWrapper> :
                    <UndoProvider>
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            ref={menuContainerRef}
                        >
                            <InputMenu
                                clearEtfSearch={clearEtfSearch}
                                clearAssetSearch={clearAssetSearch}
                                portfolio
                                setClearEtfSearch={setClearEtfSearch}
                                setClearAssetSearch={setClearAssetSearch}
                                menuContainerRef={menuContainerRef}
                            >
                                <div flexNumber={2}>
                                    <DropdownMenu
                                        handleChange={handleSelectPortfolio}
                                        id='watchlists'
                                        label='Select A Portfolio'
                                        options={portfolios}
                                        value={selectedPortfolio}
                                    />
                                    <StyledButton onClick={handlePortfolioManager}>
                                        {selectedPortfolio ?
                                            'Edit/View Portfolio' :
                                            'Create New Portfolio'
                                        }
                                    </StyledButton>
                                </div>
                                {!assetsLoading && !etfsLoading &&
                                    <div
                                        flexNumber={2}
                                    >
                                        <StyledLabel>
                                            Select ETF:
                                        </StyledLabel>
                                        <SearchDropdown
                                            clearSearch={clearEtfSearch}
                                            handleSubmit={handleEtfSelect}
                                            options={etfAssets.filter(asset => etfsWithHoldings.includes(asset.symbol))}
                                            position='bottom'
                                            setClearSearch={setClearEtfSearch}
                                            dropdownContainerRef={menuContainerRef}

                                        />
                                    </div>
                                }
                            </InputMenu>
                        </div>
                    </UndoProvider>
                }
                {analyzing &&
                    <CoverContainer>
                        <Loading />
                        <Heading style={{ color: AppColors.MRWHITE }}>
                            Analyzing
                        </Heading>
                    </CoverContainer>
                }
                {analysisFailed &&
                    <CoverContainer>
                        <Heading style={{ color: AppColors.MRWHITE }}>
                            Analysis Failed. Please try again.
                        </Heading>
                        <Heading style={{ color: AppColors.MRWHITE }}>
                            If this continues please contact us at <a style={{ fontSize: 18}} href = "mailto: support@marketreader.com">support@marketreader.com</a>.
                        </Heading>
                    </CoverContainer>
                }
                {translationFailed &&
                    <CoverContainer>
                        <Heading style={{ color: AppColors.MRWHITE }}>
                            Translation Failed. Please try again.
                        </Heading>
                        <Heading style={{ color: AppColors.MRWHITE }}>
                            If this continues please contact us at <a style={{ fontSize: 18}} href = "mailto: support@marketreader.com">support@marketreader.com</a>.
                        </Heading>
                    </CoverContainer>
                }
            </PortfolioContainer>
        </>
    )
};

export default PortfolioAnalyzer;
