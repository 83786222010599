import { useContext, useState, useEffect } from "react";
import { StateContext } from "../../Controllers/contexts/StateContext";
import { OpenArrowUpIcon } from "../Icons/Icons";
import { AppColors } from "../StyledComponents/Graphics";
import styled from "styled-components";
import supabase from "../../Controllers/services/supabaseClient";

const ChatAssistContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    position: sticky;
    bottom: 0;
    background-color: ${AppColors.MRWHITE};
    border-radius: 10px 10px 0 0;
    max-height: 600px;
`;

const ArrowContainer = styled.div`
    position: absolute;
    right: 10px;
    bottom: 15px;
    cursor: pointer;
    box-shadow: 0 0 3px rgba(26, 115, 232, 0.5);
    border-radius: 50%;

    svg {
        circle, path {
            stroke: ${AppColors.MRGREY70};
        }
    }

    &:hover {
        svg {
            circle, path {
                stroke: ${AppColors.MRBLACK};
            }
        }
    }
`;

const TextArea = styled.textarea`
    width: 100%;
    min-height: 50px;
    max-height: 300px;
    padding: 12px 45px 12px 12px;
    background-color: ${AppColors.MRWHITE};
    border: 1px solid ${AppColors.MRGREY70};
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    resize: none;
    font-family: inherit;
    font-size: 24px;
    line-height: 1.5;
    
    &:focus {
        outline: none;
        border-color: ${AppColors.MRNAVY50};
        box-shadow: 0 0 3px rgba(26, 115, 232, 0.5);
    }
`;

const ChatAssist = () => {
    const {
        analyzing,
        analyzingChat,
        headers,
        setAnalyzingChat,
        setLoadingDots,
        setHistory,
        setStreaming,
        streaming,
    } = useContext(StateContext);
    const [userInput, setUserInput] = useState('');

    useEffect(() => {
        let interval;
        if (setAnalyzingChat) {
            interval = setInterval(() => {
                setLoadingDots(prev => {
                    if (prev === '....') return '.';
                    return prev + '.';
                });
            }, 500);
        } else {
            setLoadingDots('');
        }
        return () => clearInterval(interval);
    }, [setAnalyzingChat]);

    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmitChat();
        }
    };

    const handleSubmitChat = async () => {
        setAnalyzingChat(true);
        const currentInput = userInput;
        setUserInput('');

        const newTimestamp = Date.now();

        setHistory(prevState => ({
            data: {
                ...prevState.data,
                [newTimestamp]: {
                    inputs: { userInput: currentInput },
                    output: '',
                },
            },
        }));

        try {
            const { data: response, error } = await supabase.functions.invoke('chatAssistant', {
                body: {
                    assistant_id: headers.assistantId,
                    thread_id: headers.threadId,
                    user_input: userInput,
                },
                responseType: 'stream',
            });

            if (error) {
                console.log('Error invoking chat assistant:', error);
            }
          
            setAnalyzingChat(false);
          
            const reader = response.body?.getReader();
            if (!reader) {
                throw new Error('No reader available');
            }
          
            const decoder = new TextDecoder();
            let accumulatedText = '';
            setStreaming(true);
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                
                const chunk = decoder.decode(value);
            
                const messages = chunk.split('\n\n');
                
                for (const message of messages) {
                    if (message.startsWith('data: ')) {
                        const jsonStr = message.slice(6);
                        try {
                            const data = JSON.parse(jsonStr);
                            
                            accumulatedText += data.content;

                            setHistory(prevState => {
                                const existingItem = prevState.data[newTimestamp];
                                return {
                                    data: {
                                        ...prevState.data,
                                        [newTimestamp]: {
                                            ...existingItem,
                                            output: accumulatedText,
                                        },
                                    },
                                };
                            });
                        } catch (e) {
                            console.log('Error parsing message:', jsonStr, e);
                        }
                    } else if (message.startsWith('error: ')) {
                        console.error('Error message from stream:', message);
                    }
                }
            }
            setStreaming(false);
        } catch (error) {
            console.error('Error processing stream:', error);
        }
    };

    return (
        <ChatAssistContainer>
            <div style={{ width: '100%', position: 'relative' }}>
                <TextArea
                    disabled={analyzingChat || analyzing || streaming}
                    placeholder='Ask me about your portfolio...'
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    value={userInput}
                    rows={1}    
                    onInput={(e) => {
                        e.target.style.height = 'auto';
                        e.target.style.height = e.target.scrollHeight + 'px';
                    }}
                />
                {userInput.length > 0 && (
                    <ArrowContainer onClick={handleSubmitChat}>
                        <OpenArrowUpIcon style={{ width: '30px', height: '30px' }} />
                    </ArrowContainer>
                )}
            </div>
        </ChatAssistContainer>
    )
}

export default ChatAssist;