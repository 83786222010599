import styled from 'styled-components';
import { AppColors } from '../StyledComponents/Graphics';
import { useState } from 'react';

const StyledTooltip = styled.div`
  position: absolute;
  width: ${({ tooltipWidth }) => `${tooltipWidth}px` || 'fit-content'};
  display: flex;
  align-items: center;
  background-color: ${AppColors.MRDARKGREY};
  color: ${AppColors.MRWHITE};
  border-radius: 2px;
  padding: 5px 10px;
  font-size: 14px;
  white-space:  ${({ tooltipWidth }) => tooltipWidth ? null :  'nowrap'};
  z-index: 999;
  transition: opacity 0.3s;
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};

  ${props => props.position === 'top' &&
        `
            bottom: calc(100% + 6px);
            left: 50%;
            transform: translateX(-50%);
        `
  }

  ${props => props.position === 'bottom' &&
        `
            top: calc(100% + 6px);
            left: 50%;
            transform: translateX(-50%);
    `
  }

  ${props => props.position === 'right' && 
        `
            top: 50%;
            left: calc(100% + 10px);
            transform: translateY(-50%);
        `
    }
  
  ${props =>
    props.position === 'left' &&
        `
            top: 50%;
            right: calc(100% + 10px);
            transform: translateY(-50%);
        `
    }

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: 6px solid transparent;
  }

  ${props => props.position === 'top' &&
        `
            &:before {
                top: 100%;
                left: 50%;
                transform: translateX(-50%) rotate(180deg);
                border-top: none;
                border-bottom: 6px solid ${AppColors.MRDARKGREY};
            }
        `
  }

  ${props => props.position === 'bottom' &&
        `
            &:before {
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%) rotate(180deg);
                border-top: 6px solid ${AppColors.MRDARKGREY};
                border-bottom: none;
            }
    `
  }

  ${props => props.position === 'right' && 
        `
            &:before {
                top: 50%;
                left: -14px;
                transform: translateY(-50%) rotate(180deg);
                border-left: 8px solid ${AppColors.MRDARKGREY};
            }
        `
    }
  
  ${props =>
    props.position === 'left' &&
        `
            &:before {
                top: 50%;
                right: -14px;
                transform: translateY(-50%) rotate(180deg);
                border-right: 8px solid ${AppColors.MRDARKGREY};
            }
        `
    }
`;


const Tooltip = ({ children, position, text, tooltipWidth, width }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  return (
    <div
      style={{ position: 'relative', display: 'inline-block', width: width }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {isTooltipVisible && (
        <StyledTooltip position={position} tooltipWidth={tooltipWidth} visible={isTooltipVisible}>
          {typeof text === 'string' ?
            text :
            <div>
              {text.map((line) => (
                  <div>
                    {line}
                  </div>
                ))}
            </div>
          }
        </StyledTooltip>
      )}
    </div>
  );
}
  
export default Tooltip;
