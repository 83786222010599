import styled from "styled-components";

const MainContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

const MainContent = ({ children }) => {
    return (
        <MainContainer>
            {children}
        </MainContainer>
    );
};

export default MainContent;
