const equalArrays = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }

    const frequencyMap = {};

    for (let val of arr1) {
        frequencyMap[val] = (frequencyMap[val] || 0) + 1;
    }

    for (let val of arr2) {
        if (!frequencyMap[val]) {
            return false;
        }
        frequencyMap[val]--;
    }

    for (let key in frequencyMap) {
        if (frequencyMap[key] !== 0) {
            return false;
        }
    }

    return true;
};

  export default equalArrays;
