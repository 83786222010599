
import { BackIcon } from '../Icons/Icons';
import { CancelButton } from '../StyledComponents/UserFormStyledComponents';
import { useUndo } from '../../Controllers/contexts/UndoContext';

const UndoButton = () => {
    const { state, undo } = useUndo();

    if (state.history.length === 0) {
        return null;
    }

    return (
        <CancelButton onClick={undo}>
            Undo
            <BackIcon />
        </CancelButton>
    );
};

export default UndoButton;