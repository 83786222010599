import { useContext, useEffect, useRef, useState } from 'react';
import { StateContext } from "../../../Controllers/contexts/StateContext";
import { CancelButton, Input, StyledButton, StyledLabel, SubmitButton, WarningText } from '../../../Global/StyledComponents/UserFormStyledComponents';
import styled from "styled-components";
import { CloseIcon, ExclamationIcon } from "../../../Global/Icons/Icons";
import { useUndo } from '../../../Controllers/contexts/UndoContext';
import UndoButton from '../../../Global/Components/UndoButton';
import AssetCheck from '../../../Controllers/utils/assetCheck';
import supabase from '../../../Controllers/services/supabaseClient';
import { AuthContext } from '../../../Controllers/contexts/AuthContext';
import { BlankSpacer } from '../../../Global/StyledComponents/AppStyledComponents';
import { AppColors } from '../../../Global/StyledComponents/Graphics';
import ToggleSwitch from '../../../Global/Components/ToggleSwitch';
import Tooltip from '../../../Global/Components/Tooltip';

const StyledTableContainer = styled.div`
    max-height: 500px;
    overflow-y: auto;
    width: 100%;
`;

const StyledTable = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledRow = styled.div`
    display: flex;
    padding: 5px;
    align-items: center;
`;

const StyledCell = styled.div`
    flex: 1;
    padding: 0 10px;
`;

const PortfolioManager = () => {
    const { addAction, state: undoState } = useUndo();
    const {
        assetKeys,
        assetNames,
        assetTickers,
        fetchAllPortfolios,
        logError,
        selectedPortfolio,
        setInputs,
        setSelectedPortfolio,
        setShowPortfolioManager,
    } = useContext(StateContext);
    const [disabled, setDisabled] = useState(false)
    const [error, setError] = useState(false);
    const [portfolioName, setPortfolioName] = useState(selectedPortfolio?.meta?.name || '');
    const [rows, setRows] = useState([{ price: null, symbol: '', valid: true, weight: null }]);
    const tableContainerRef = useRef(null);
    const { user } = useContext(AuthContext);
    const [totalWeight, setTotalWeight] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [weightType, setWeightType] = useState('percent');

    const equalizeWeights = () => {
        const symbolCount = rows.filter(row => row.symbol !== '').length;
        if (symbolCount === 0) return;

        const equalWeight = 100 / symbolCount;
        setRows(prevRows => prevRows.map(row => ({
            ...row,
            weight: row.symbol !== '' ? Number(equalWeight.toFixed(2)) : null
        })));

        setTimeout(() => {
            setTotalWeight(100);
        }, 100);
    };

    const normalizeWeights = () => {
        const filledRows = rows.filter(row => row.symbol);
        const currentTotal = filledRows.reduce((sum, row) => sum + (parseFloat(row.weight) || 0), 0);
        if (currentTotal === 0) {
            setTotalWeight(0);
            return;
        }
    
        setRows(prevRows => prevRows.map(row => {
            if (row.symbol) {
                const newWeight = ((row.weight || 0) / currentTotal) * 100;
                return {
                    ...row,
                    weight: Number(newWeight.toFixed(2))
                };
            }
            return row;
        }));

        setTimeout(() => {
            setTotalWeight(100);
        }, 100);
    };

    useEffect(() => {
        const newTotal = rows.reduce((sum, row) => sum + (Number(row.weight) || 0), 0);
        setTotalWeight(Number(newTotal.toFixed(2)));
    }, [rows]);

    const scrollToBottom = () => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollTop = tableContainerRef.current.scrollHeight;
        }
    };

    const findEmptyRowIndex = () => {
        return rows.findIndex(row => !row.symbol);
    };

    const handleDeleteRow = (index) => {
        const deletedRow = rows[index];
        setRows(prevState => prevState.filter((_, idx) => idx !== index));
        addAction({ type: 'DELETE', index, row: deletedRow });
    };

    const handleAddRow = () => {
        const emptyRowIndex = findEmptyRowIndex();
        if (emptyRowIndex !== -1) {
            document.querySelectorAll("input")[emptyRowIndex].focus();
        } else {
            setRows(prevState => [...prevState, { symbol: '', weight: null }]);
            addAction({ type: 'ADD', row: { symbol: '', weight: null } });
        }
    };

    const isDuplicate = (symbol) => {
        return rows.some(row => row.symbol.toUpperCase() === symbol.toUpperCase());
    };

    const handleChangePortfolioName = (e) => {
        const { value } = e.target;

        setPortfolioName(value);
    }

    const handleCreateNewPortfolio = () => {
        setDisabled(true);
        const portfolio = {};
        rows.filter(row => row.symbol).forEach(row => portfolio[row.symbol.toUpperCase()] = row.weight);

        supabase.from('user_preferences').insert([{
            data: portfolio,
            meta: {
                name: portfolioName,
                created_at: new Date(),
            },
            type: 'portfolio',
            user_id: user?.id,
        }]).select().then(res => {
            if (res.error) {
                logError(res.error);
                setError(true);
            } else {
                setInputs(prevState => ({
                    ...prevState,
                    assets: portfolio,
                }));
                fetchAllPortfolios({ retry: true });
                setSelectedPortfolio(res?.data?.[0]);
                setDisabled(false);
                setShowPortfolioManager(false);
            }
        })
    };

    const handleDeleteAll = () => {
        setRows([{ symbol: '', weight: null }]);
    };

    const handleUpdatePortfolio = () => {
        setDisabled(true);
        const portfolio = {};
        rows.filter(row => row.symbol).forEach(row => portfolio[row.symbol.toUpperCase()] = row.weight);
        const updatedPortfolio = {
            data: portfolio,
            id: selectedPortfolio?.id,
            meta: {
                ...selectedPortfolio?.meta,
                name: portfolioName,
            },
            user_id: selectedPortfolio?.user_id,
        };

        supabase.from('user_preferences').update(updatedPortfolio)
            .eq('id', selectedPortfolio.id).select().then(res => {
            if (res.error) {
                logError(res.error);
                setError(true);
            } else {
                fetchAllPortfolios({ retry: true });
                setSelectedPortfolio(res?.data?.[0]);
                setInputs(prevState => ({
                    ...prevState,
                    assets: portfolio,
                }));
                setDisabled(false);
                setShowPortfolioManager(false);
            }
        })
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const pastedData = event.clipboardData.getData('text').trim();
        const newRows = pastedData.split('\n').map(row => {
            const [symbol, weight] = row.split('\t');
            return { symbol: symbol.trim(), weight: weight ? parseFloat(weight.trim()) : null };
        });

        newRows.forEach((row, index) => {
            AssetCheck({
                assetKeys,
                assetNames,
                assetTickers,
                handleSubmit: () => {},
                inputVal: row.symbol,
                setValidTicker: (valid) => {
                    newRows[index].valid = valid;
                },
            });
        });

        setRows(prevState => {
            const emptyRowIndex = findEmptyRowIndex();
            const updatedRows = [...prevState];

            if (emptyRowIndex !== -1) {
                updatedRows[emptyRowIndex] = newRows[0];
                newRows.shift();
            }

            const filteredNewRows = newRows.filter(row => !isDuplicate(row.symbol));

            return [...updatedRows, ...filteredNewRows];
        });
        scrollToBottom();
        handleAddRow();

        addAction({ type: 'PASTE', rows: newRows });
    };


    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAddRow();
            setTimeout(() => {
                const inputs = document.querySelectorAll("input");
                inputs[inputs.length - 3].focus();
            }, 0);
        }
    };

    const handleChangePrice = (index, value) => {
        setRows(prevState => {
            const newRows = [...prevState];
            newRows[index].price = value;
            return newRows;
        });
    };

    const handleChangeWeight = (index, value) => {
        setRows(prevState => {
            const newRows = [...prevState];
            newRows[index].weight = value;
            return newRows;
        });
    };

    const handleChangeSymbol = (index, value) => {
        const handleSubmit = (value) => {
            supabase.from('card_data').select('close').eq('symbol', value).then(res => {
                if (res.error) {
                    logError(res.error);
                    setError(true);
                } else {
                    setRows(prevState => {
                        const newRows = [...prevState];
                        newRows[index].price = res?.data?.[0]?.close;
                        return newRows; 
                    });
                }
            });
        };

        if (weightType === 'shares') {
            AssetCheck({
                assetKeys,
                assetNames,
                assetTickers,
                handleSubmit,
                inputVal: value,
            });
        }

        setRows(prevState => {
            const newRows = [...prevState];
            newRows[index].symbol = value;
            return newRows;
        });

        AssetCheck({
            assetKeys,
            assetNames,
            assetTickers,
            handleSubmit: () => {},
            inputVal: value,
            setValidTicker: (valid) => setRows(prevState => {
                const newRows = [...prevState];
                newRows[index].valid = valid;
                return newRows;
            }),
        });

        if (index === rows.length - 1) {
            handleAddRow();
        }
    };

    useEffect(() => {
        if (undoState.undoing) {
            const { type, index, row, rows: pastedRows } = undoState.undoing;
            if (type === 'DELETE') {
                setRows(prevState => {
                    const newRows = [...prevState];
                    newRows.splice(index, 0, row);
                    return newRows;
                });
            } else if (type === 'ADD') {
                setRows(prevState => prevState.filter((_, idx) => idx !== index));
            } else if (type === 'PASTE') {
                setRows(prevState => prevState.slice(0, prevState.length - pastedRows.length));
            }
        }
    }, [undoState.undoing]);

    useEffect(() => {
        if (weightType === 'shares') {
            setTotalPrice(rows.reduce((sum, row) => sum + (row.price || 0) * (row.weight || 0), 0));
        }
    }, [rows]);

    useEffect(() => {
        if (selectedPortfolio) {
            setRows(() => {
                const portfolio = selectedPortfolio.data;
                const rows = Object.keys(portfolio).map(key => ({ symbol: key, weight: portfolio[key] }));

                rows.forEach((row, index) => {
                    AssetCheck({
                        assetKeys,
                        assetNames,
                        assetTickers,
                        handleSubmit: () => {},
                        inputVal: row.symbol,
                        setValidTicker: (valid) => {
                            rows[index].valid = valid;
                        },
                    });
                });
                return rows;
            });
        }
    }, [selectedPortfolio])

    useEffect(() => {
        if (weightType === 'shares') {
            rows.forEach((row, index) => {
                const handleSubmit = (value) => {
                    supabase.from('card_data').select('close').eq('symbol', value).then(res => {
                        if (res.error) {
                            logError(res.error);
                            setError(true);
                        } else {
                            setRows(prevState => {
                                const newRows = [...prevState];
                                newRows[index].price = res?.data?.[0]?.close;
                                return newRows; 
                            });
                        }
                    });
                };

                AssetCheck({
                    assetKeys,
                    assetNames,
                    assetTickers,
                    handleSubmit,
                    inputVal: row.symbol,
                });
            });
        }
    }, [weightType]);

    return (
        <div onPaste={handlePaste} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <StyledLabel>
                Portfolio Name:
            </StyledLabel>
            <Input value={portfolioName} onChange={handleChangePortfolioName}/>
            <BlankSpacer />
            <StyledLabel>
                Click anywhere to paste in a list of tickers and/or weights to add them to your portfolio.
            </StyledLabel>
            <StyledTableContainer ref={tableContainerRef}>
                <StyledTable>
                    <StyledRow style={{ position: 'sticky', top: 0, backgroundColor: AppColors.MRWHITE, zIndex: 1 }}>
                        <div style={{ width: '20px' }}></div>
                        <StyledCell><strong>Symbol</strong></StyledCell>
                        <StyledCell style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <strong>Weight</strong>
                            <ToggleSwitch
                                offLabel='Percent'
                                onLabel='# of Shares'
                                handleClick={() => setWeightType(weightType === 'percent' ? 'shares' : 'percent')}
                                isOn={weightType === 'shares'}
                                width={weightType === 'shares' ? '115px' : '85px'}
                            />
                        </StyledCell>
                        {weightType === 'shares' &&
                            <StyledCell><strong>Price ($)</strong></StyledCell>
                        }
                    </StyledRow>
                    {rows.map((row, index) => (
                        <StyledRow key={index}>
                            {rows.length === 1 ?
                                <div style={{ width: '15px' }}></div> :
                                <CloseIcon onClick={() => handleDeleteRow(index)} />
                            }
                            <StyledCell>
                                <div style={{ position: 'relative', width: '100%' }}>
                                    <Input
                                        value={row.symbol}
                                        onChange={(e) => handleChangeSymbol(index, e.target.value)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                    />
                                    {!row.valid &&
                                        <div style={{ height: 20, position: 'absolute', right: 5, top: '50%', transform: 'translateY(-50%)' }}>
                                            <Tooltip
                                                position='right'
                                                text='Asset Not In System'
                                            >
                                                <ExclamationIcon />
                                            </Tooltip>
                                        </div>
                                    }
                                </div>
                            </StyledCell>
                            <StyledCell>
                                <Input
                                    type='number'
                                    value={row.weight || ''}
                                    onChange={(e) => handleChangeWeight(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                />
                            </StyledCell>
                            {weightType === 'shares' &&
                                <StyledCell>
                                    <Input
                                        type='number'
                                        value={row.price || ''}
                                        onChange={(e) => handleChangePrice(index, e.target.value)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                    />
                                </StyledCell> 
                            }
                        </StyledRow>
                    ))}
                </StyledTable>
            </StyledTableContainer>
            <StyledTableContainer>
                <StyledTable>
                    <StyledRow>
                        <StyledCell>
                            {(weightType === 'percent' && totalWeight !== 100 && totalWeight !== 0) &&
                                <WarningText>
                                    Total weight must equal 100%.
                                </WarningText>
                            }
                        </StyledCell>
                        {weightType === 'shares' &&
                            <StyledCell></StyledCell>
                        }
                        <StyledCell style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            {weightType === 'percent' ?
                                'Total Weight: '  + totalWeight + '%' :
                                'Total Price: $' + totalPrice.toFixed(2)
                            }
                        </StyledCell>
                    </StyledRow>
                </StyledTable>
            </StyledTableContainer>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', paddingBottom: 50 }}>
                {rows.length > 1 &&
                    <CancelButton onClick={handleDeleteAll}>
                        Delete All
                    </CancelButton>
                }
                {weightType === 'percent' &&
                    <>
                        <StyledButton style={{ margin: 5 }} onClick={normalizeWeights}>
                            Normalize
                        </StyledButton>
                        <StyledButton style={{ margin: 5 }} onClick={equalizeWeights}>
                            Equalize
                        </StyledButton>
                    </>
                }
            </div>
            <div style={{ alignItems: 'center', display: 'flex', position: 'absolute', bottom: 10, right: 10, gap: 10 }}>
                {/* <UndoButton /> */}
                {selectedPortfolio ?
                    <SubmitButton disabled={disabled || (weightType === 'percent' && totalWeight !== 100)} onClick={handleUpdatePortfolio}>
                        Update/Use For Analysis
                    </SubmitButton> :
                    <SubmitButton disabled={disabled || (weightType === 'percent' && totalWeight !== 100)} onClick={handleCreateNewPortfolio}>
                        Create/Use Portfolio
                    </SubmitButton>
                }
                {error &&
                    <WarningText style={{ textAlign: 'center' }}>
                        Failed to Create Portfolio
                    </WarningText>
                }
            </div>
        </div>
    );
};

export default PortfolioManager;
