import { useState, useEffect, useCallback } from 'react';
import supabase from '../services/supabaseClient';

const useFetchPortfolios = ({ logError, setShowErrorWindow }) => {
    const [portfolios, setPortfolios] = useState([]);
    const [portfoliosLoading, setPortfoliosLoading] = useState(true);

    const fetchAllPortfolios = useCallback(async ({ retry = true }) => {
        const res = await supabase.from('user_preferences').select('*').eq('type', 'portfolio');

        if (res?.error) {
            const error = new Error(res?.error?.message);
            logError(error.message, undefined, error);

            if (retry) {
                fetchAllPortfolios({ retry: false });

                return;
            }

            setShowErrorWindow(true);

            return;
        }
        
        const portfoliosData = res?.data?.map((portfolio) => {
            return {
                label: portfolio?.meta?.name,
                value: JSON.stringify(portfolio),
            };
        });

        const allPortfolios = [
            {
                label: '',
                value: null,
            },
          ...portfoliosData,
        ];

        setPortfolios(allPortfolios);
        setPortfoliosLoading(false);
    }, []);

    useEffect(() => {
        fetchAllPortfolios({ retry: true });
    }, []);

    return {
        fetchAllPortfolios,
        portfolios,
        portfoliosLoading,
    };
};

export default useFetchPortfolios;
