import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

import { Heading, StyledButton, SubmitButton } from "../../Global/StyledComponents/UserFormStyledComponents";
import { StateContext } from "../../Controllers/contexts/StateContext";
import { useContext } from "react";
import { BlankSpacer, FeedItem, Timestamp } from "../../Global/StyledComponents/AppStyledComponents";
import { formatDate, formatTime } from "../../Controllers/utils";
import Loading from "../../Global/Components/LoadingSpinner";

const DashboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    max-width: 50%;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    justify-content: flex-start;
    align-items: center;
    max-height: 90%;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
        overflow: hidden;
    }
`;

const Dashboard = () => {
    const {
        isLoading,
        macroSummary,
        startAnalysis,
        setStartAnalysis,
    } = useContext(StateContext);
    const navigate = useNavigate();

    if (isLoading) {
        return (
            <DashboardContainer>
                <Loading />
            </DashboardContainer>
        );
    }

    return(
        <DashboardContainer>
            {startAnalysis ? 
                <Container>
                    <StyledButton onClick={() => setStartAnalysis(false)} style={{ margin: 0, position: 'absolute', top: '20px', left: '20px' }}>
                        Back
                    </StyledButton>
                    <Heading style={{ marginBottom: '30px', marginTop: '50px' }}>
                        Select Analysis Type
                    </Heading>
                    <SubmitButton onClick={() => navigate('/watchlist')} style={{ maxWidth: '400px', width: '90%' }}>
                        Analyze Asset Group
                    </SubmitButton>
                    <div style={{ fontSize: '16px', display: 'flex', flexDirection: 'column', margin: '20px 0' }}>
                        <div style={{ fontWeight: 'bold' }}>
                            Create an analysis from:
                        </div>
                        <ul style={{ margin: 0 }}>
                            <li>
                                One or more assets
                            </li>
                            <li>
                                The components from an ETF
                            </li>
                            <li>
                                One of your saved Watchlists
                            </li>
                        </ul>
                    </div>
                    <BlankSpacer />
                    <BlankSpacer />
                    <BlankSpacer />
                    <BlankSpacer />
                    <SubmitButton onClick={() => navigate('/portfolio')} style={{ maxWidth: '400px', width: '90%' }}>
                        Analyze Portfolio
                    </SubmitButton>
                    <div style={{ fontSize: '16px', display: 'flex', flexDirection: 'column', margin: '20px 0' }}>
                        <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
                            Create and analyze a weighted portfolio of assets.
                        </div>
                    </div>
                </Container> :
                <>
                    <Container
                        style={{
                            maxHeight: 'calc(90% - 100px)',
                        }}
                    >
                        <Heading 
                            style={{ 
                                margin: '5px',
                            }}>
                            <div>
                                Current Macro Overview
                            </div>
                            <div>
                                {formatDate(macroSummary?.time_utc)}
                            </div>
                        </Heading>
                        <FeedItem>
                            <p style={{ margin: 0 }}>
                                <b>[{formatTime(macroSummary?.time_utc)}]: </b>
                                {macroSummary?.data?.summary.split('\n').map((paragraph) => (
                                    <>
                                        {paragraph}
                                        <br />
                                    </>
                                ))}&nbsp;
                                <Timestamp>({moment(macroSummary?.time_utc).fromNow()})</Timestamp>
                            </p>
                        </FeedItem>
                    </Container>
                    <SubmitButton style={{ width: '50%' }} onClick={() => setStartAnalysis(true)}>
                        Start Analysis
                    </SubmitButton>
                </>
            }
        </DashboardContainer>
    )
};

export default Dashboard;
