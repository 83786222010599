import { useEffect, useState } from "react";
import supabase from "../services/supabaseClient";

const useFetchInputPacket = ({ logError, setShowErrorWindow }) => {
    const [inputPacket, setInputPacket] = useState({});
    const [inputPacketLoading, setInputPacketLoading] = useState(true)

    const fetchInputPacket = async ({ retry = false }) => {
        const { data, error } = await supabase.functions.invoke('inputPacket');

        if (error) {
            logError(error);
            
            if (retry) {
                fetchInputPacket({ retry: false });
                
                return;
            }
            
            setShowErrorWindow(true);
            return;
        }

        setInputPacket(JSON.parse(data)?.inputPacket);
        setInputPacketLoading(false);
    };

    useEffect(() => {
        fetchInputPacket({ retry: true });
    }, [])

    return ({
        inputPacket,
        inputPacketLoading,
    });
};

export default useFetchInputPacket;
