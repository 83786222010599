import supabase from "../services/supabaseClient";

const checkPerformance = async ({ start, end, symbols }) => {
    if (start > end) return;
    const res = await supabase.rpc('portfolio_analysis_performance', {
        _start: start,
        _end: end,
        _symbols: symbols,
    });

    return res;
};

export default checkPerformance;
