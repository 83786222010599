import { useEffect } from 'react';
import { CloseIcon } from '../../Icons/Icons';
import { AppColors } from '../../StyledComponents/Graphics';
import styled from 'styled-components';

const ModalContainer = styled.div`
    width: ${(props) => props.width ? props.width : '80%'};
    background: ${AppColors.MRWHITE};
    /* background-image: ${props => props.image ? "url('/img/BackgroundImage.svg')" : null}; */
    color: ${AppColors.MRBLACK};
    padding: 20px;
    border-radius: 10px;
    max-height: 75vh;
    overflow: auto;
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9199;
`;

const ModalLayout = ({ children, image = false, onClose, width }) => {
    const handleClose = () => {
        onClose(false);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                handleClose();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <ModalOverlay onClick={handleClose}>
            <ModalContainer image={image} onClick={(e) => e.stopPropagation()} style={{ position: 'relative' }} width={width}>
                <div style={{ position: 'absolute', right: 10, top: 10, zIndex: 999 }}>
                    <CloseIcon onClick={handleClose} />
                </div>
                {children}
            </ModalContainer>
        </ModalOverlay>
    );
};

export default ModalLayout;
