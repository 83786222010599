import styled from 'styled-components';
import { AppColors } from '../StyledComponents/Graphics';

const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Spinner = styled.div`
    display: inline-block;
    width: ${props => props?.dimensions?.width || '64px'};
    height: ${props => props?.dimensions?.height || '64px'};
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top-color: ${AppColors.MRWHITE};
    border-radius: 50%;
    animation: spin 1s ease-in-out infinite;

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;

const Loading = ({ dimensions }) => {
    return (
        <LoadingWrapper>
            <Spinner dimensions={dimensions} />
        </LoadingWrapper>
    );
};

export default Loading;
