const getDateRange = (period) => {
    const today = new Date();
    let startDate;
    let endDate = today;

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    switch (period) {
        case 'past-week':
            startDate = new Date();
            startDate.setDate(today.getDate() - 7);
            break;
        case 'week-to-date':
            startDate = new Date(today);
            startDate.setDate(today.getDate() - today.getDay());
            break;
        case 'past-month':
            startDate = new Date();
            startDate.setMonth(today.getMonth() - 1);
            break;
        case 'month-to-date':
            startDate = new Date(today.getFullYear(), today.getMonth(), 1);
            break;
        case 'past-quarter':
            startDate = new Date();
            startDate.setMonth(today.getMonth() - 3);
            break;
        case 'quarter-to-date':
            const currentMonth = today.getMonth();
            const startMonth = currentMonth - (currentMonth % 3);
            startDate = new Date(today.getFullYear(), startMonth, 1);
            break;
        case 'year-to-date':
            startDate = new Date(today.getFullYear(), 0, 1);
            break;
        default:
            throw new Error('Invalid period value');
    }

    return { startDate: formatDate(startDate), endDate: formatDate(endDate) };
};

export default getDateRange;
