import round from "./round";

const getWeightedReturn = (data, weights) => {
    if (!data || data.length === 0) return 0;

    const totalWeight = Object.values(weights).reduce((acc, weight) => acc + (parseFloat(weight) || 0), 0);

    const totalWeightedReturn = data.reduce((acc, obj) => {
        const weight = weights[obj.symbol] || 0;
        return acc + obj.period_return * (parseFloat(weight) / totalWeight);
    }, 0);

    return round(totalWeightedReturn * 100, 1);
};

export default getWeightedReturn;
