const AssetCheck = ({
    assetKeys,
    assetNames,
    assetTickers,
    handleSubmit,
    inputVal,
    setDropdownOpen,
    setValidTicker,
}) => {
    if (assetTickers.includes(inputVal.toUpperCase())) {
        if (setValidTicker) setValidTicker(true);
        handleSubmit(inputVal.toUpperCase());
        if (setDropdownOpen) setDropdownOpen(false);
        return true;
    } else if (assetNames.includes(inputVal.toLowerCase())) {
        if (setValidTicker) setValidTicker(true);
        handleSubmit(assetKeys[inputVal.toLowerCase()]);
        if (setDropdownOpen) setDropdownOpen(false);
        return true;
    } else if (inputVal === '') {
        if (setValidTicker) setValidTicker(true);
        return true;
    }

    if (setValidTicker) setValidTicker(false);
    return false;
};

export default AssetCheck;
