import { useEffect, useState } from "react";
import supabase from "./Controllers/services/supabaseClient";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "./Pages/Login/Login";
import ProtectedRoute from "./Global/Components/ProtectedRoute";
import { AuthProvider } from "./Controllers/contexts/AuthContext";
import { StateProvider } from "./Controllers/contexts/StateContext";
import Dashboard from "./Pages/Dashboard/Dashboard";
import { GlobalStyles } from "./Global/StyledComponents/GlobalStyles";
import Footer from "./Global/Components/Footer";
import Header from "./Global/Layouts/Header/Header";
import { Body } from "./Global/StyledComponents/AppStyledComponents";
import MainContent from "./Global/Layouts/MainContent/MainContent";
import PortfolioAnalyzer from "./Pages/PortfolioAnalyzer/PortfolioAnalyzer";
import WatchlistAnalyzer from "./Pages/WatchlistAnalyzer/WatchlistAnalyzer";
import Modals from "./Pages/Modals/Modals";

const noRedirectPaths = [
  '/authConfirm',
  '/forgotPassword',
  '/setPassword',
  '//setPassword',
];

const redirectToDashboard = [
];

const App = () => {
  const [customerProfile, setCustomerProfile] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [refreshed, setRefreshed] = useState(false);
  const [session, setSession] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [user, setUser] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const getUser = async () => {
    const { data, error } = await supabase.auth.getSession();
    const session = data?.session;
    const user = session?.user || undefined;
    if (!user) {
      setIsAuthenticated(false);
      if (!noRedirectPaths.includes(pathname)) {
        navigate('/login' + window.location.search)
      }
      return;
    }
    setUser(user);
    setSession(session);

    if (user?.role === 'authenticated') {
      const profileRes = await supabase.from('profiles').select('*');
      const { billing_email } = profileRes?.data?.[0];

      const profile = await supabase.functions.invoke('customerProfile', {
        body: {
          billingEmail: billing_email,
          userEmail: user.email,
        },
      });
      setCustomerProfile(JSON.parse(profile?.data));

      const { accessLevels, subscriptions } = JSON.parse(profile?.data);
      setSubscription(subscriptions[0]);

      if (!subscriptions.length) {
        navigate('/selectPlan')

        return;
      }

      if (subscriptions[0].status === 'paused' && pathname === '/claimOffer') {
        return;
      }

      if (accessLevels.includes('pro')) {

        setIsAuthenticated(true);
        navigate(redirectToDashboard.includes(pathname) ? '/' : pathname);
      } else {
        navigate('/accessDenied', { state: { user: { email: user.email, billingEmail: billing_email } } });

        return;
      }

    } else {
      console.log(error);
    }
    setRefreshed(true);
    return;
  };

  useEffect(() => {
    getUser();
  }, []);
  return (
    <>
      <GlobalStyles />
      <Routes>
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} refreshed={refreshed} />}>
          <Route path='*' element={
            <AuthProvider values={{
              customerProfile,
              session,
              setSession,
              setUser,
              user,
            }}>
              <StateProvider
                values={{
                  customerProfile,
                  session,
                  setIsAuthenticated,
                  setSession,
                  setUser,
                  subscription,
                  user,
                }}
              >
                <Header />
                <Body>
                  <MainContent>
                    <Routes>
                      <Route exact path="/" element={<Dashboard setIsAuthenticated={setIsAuthenticated} setUser={setUser}/>} />
                      <Route exact path="/portfolio" element={<PortfolioAnalyzer />} />
                      <Route exact path="/watchlist" element={<WatchlistAnalyzer />} />
                    </Routes>
                  </MainContent>
                  <Modals />
                </Body>
                <Footer />
              </StateProvider>
            </AuthProvider>
          } />
        </Route>
        <Route path='/login' element={
          <Login
            setCustomerProfile={setCustomerProfile}
            setIsAuthenticated={setIsAuthenticated}
            setRefreshed={setRefreshed}
            setSession={setSession}
            setSubscription={setSubscription}
            setUser={setUser}
            user={user}
          />
        } />
      </Routes>
    </>
  );
}

export default App;
