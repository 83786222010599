import styled from "styled-components";
import {
    CancelButton,
    Input,
    StyledButton,
    StyledLabel,
    SubmitButton,
} from "../../StyledComponents/UserFormStyledComponents";
import SearchDropdown from "../../Components/SearchDropdown";
import { useContext, useEffect, useState } from "react";
import { StateContext } from "../../../Controllers/contexts/StateContext";
import DropdownMenu from "../../Components/DropdownMenu";
import { DEFAULT_INPUTS, Periods } from "../../constants";
// import { ButtonContainer, MenuContainer } from "../../../Global/Layouts/SideBar/styledComponents";
import {
    checkAndUpdateHistory,
    checkPerformance,
    getAveragePeriodReturn,
    getDateRange,
    getWeightedReturn,
    round
} from "../../../Controllers/utils";
import MultiSelectDropdown from "../../Components/MultiSelectDropdown";
import { CloseIcon, WeightIcon } from "../../Icons/Icons";
import { useUndo } from "../../../Controllers/contexts/UndoContext";
import supabase from "../../../Controllers/services/supabaseClient";
import { AppColors } from "../../StyledComponents/Graphics";
import { BlankSpacer } from "../../StyledComponents/AppStyledComponents";
import AssetCheck from "../../../Controllers/utils/assetCheck";
import { useNavigate } from "react-router-dom";

const InputMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const MenuContainer = styled.div`
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    min-width: 500px;
    height: auto;
    max-height: calc(100% - 75px);
    min-height: 300px;
    justify-content: space-between;
    padding: 16px;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow-y: auto;
`;

const MenuItem = styled.div`
    height: auto;
    width: ${({ flexNumber }) => flexNumber ? `calc(95% / ${flexNumber})` : '100%'};
`;

export const AssetView = styled.div`
    height: auto;
    width: 100%;
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    max-height: 160px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px;
    
    & > * {
        flex: 1 1 20%;
    }
`;

export const ExcludedAssets = styled.div`
    color: ${AppColors.MRRED};
    margin: 0 auto;
    width: 100%;
    text-align: center;
    max-height: 60px;
    overflow: auto;
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5);
    border-radius: 0 0 10px 10px;
    padding: 5px;
`;

const StyledListItem = styled.div`
    background: ${AppColors.MRGREY70};
    border-radius: 10px;
    margin: 5px;
    padding: 2px 5px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;


    &:hover {
      background-color: ${AppColors.MRRED};
      border: 1px solid;
      padding: 1px; 
    }
`;

export const StyledValue = styled.div`
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
    display: flex;
    align-items: center;
`;

const InputMenu = ({
    children,
    menuContainerRef,
    portfolio,
    setClearEtfSearch,
    setClearAssetSearch,
}) => {
    const { addAction, state: undoState } = useUndo();
    const {
        assets,
        assetKeys,
        assetNames,
        assetTickers,
        headers,
        inputPacket,
        inputs,
        logError,
        setAnalysisFailed,
        setAnalyzing,
        setHeaders,
        setHistory,
        setInputs,
        setSelectedPortfolio,
        setSelectedWatchlist,
        setShowHistory,
        setStartAnalysis,
        setStreaming,
    } = useContext(StateContext);
    const [missingInput, setMissingInput] = useState(false);
    const [missingAssets, setMissingAssets] = useState([]);
    const navigate = useNavigate();
    const [performanceData, setPerformanceData] = useState([]);
    
    const handleBenchmark = (value) => {
        setInputs(prevState => ({
            ...prevState,
            benchmark_asset: value,
        }));
    };

    const handleClearAssets = () => {
        setInputs(prevState => ({
            ...prevState,
            assets: [],
        }));
        setClearEtfSearch(true);
        setClearAssetSearch(true);
        setSelectedWatchlist(null);
        setSelectedPortfolio(null);
    };

    const handleDeleteTicker = (id) => {
        const index = parseInt(id);
        const deletedTicker = portfolio ?
            Object.keys(inputs?.assets)?.[index] :
            inputs?.assets?.[index];
    
        setInputs(prevState => {
            const { [deletedTicker]: _, ...remainingAssets } = prevState?.assets;

            return {
                ...prevState,
                assets: portfolio ?
                    remainingAssets :
                    prevState?.assets?.filter((_, idx) => idx !== index)
            }
        });
        addAction({ type: 'DELETE', index, ticker: deletedTicker });
    };

    const handleStartDate = (e) => {
        const { value } = e.target;

        setInputs(prevState => ({
            ...prevState,
            date_start: value,
            period: null,
        }));
    };
    const handleEndDate = (e) => {
        const { value } = e.target;

        setInputs(prevState => ({
            ...prevState,
            date_end: value,
            period: null,
        }));
    };

    const handleMacroSelection = ({ selectedOptions }) => {
        setInputs(prevState => ({
            ...prevState,
            macro_analysis: selectedOptions.map(option => option.value),
        }));
    };

    const handleSelectBias = (e) => {
        const { value } = e.target;
        console.log(value);
        setInputs(prevState => ({
            ...prevState,
            bias: value,
        }));
    };

    const handleSelectPeriod = (e) => {
        const { value } = e.target;

        if (value) {
            const { endDate, startDate } = getDateRange(value);
    
            setInputs(prevState => ({
                ...prevState,
                period: value,
                date_start: startDate,
                date_end: endDate,
            }));
        } else {
            setInputs(prevState => {
                const { period, ...rest } = prevState;

                return {
                    ...rest,
                    date_start: '',
                    date_end: '',
                };
            });
        }
    };

    const handleSelectFormat = (e) => {
        const { value } = e.target;
        setInputs(prevState => ({
            ...prevState,
            output_length: value,
        }));
    };

    const handleSubmit = async ({ retry = false }) => {
        setAnalysisFailed(false);
        if (
            !(inputs?.assets?.length || Object.keys(inputs?.assets)?.length) &&
            !inputs?.macro_analysis?.length &&
            inputs?.date_end &&
            inputs?.date_start
        ) {
            setMissingInput(true);

            return;
        }
        setAnalyzing(true);

        let filteredInputs;
        if (portfolio) {
            filteredInputs = {
                ...inputs,
                assets: Object.fromEntries(
                    Object.entries(inputs?.assets).filter(([ticker]) => !missingAssets.includes(ticker))
                )
            };
        } else {
            filteredInputs = {
                ...inputs,
                assets: inputs?.assets?.filter(ticker => !missingAssets.includes(ticker))
            };
        }

        // const res = await supabase.functions.invoke('customSummary', {
        //     body: filteredInputs,
        // });

        try {
            const { data: response, error } = await supabase.functions.invoke('customSummaryAssistant', {
                body: filteredInputs,
                responseType: 'stream',
            });

            if (error) {
                console.log('Error invoking chat assistant:', error);
            }

            const assistantId = response?.headers?.get('x-assistant-id');
            const threadId = response?.headers?.get('x-thread-id');

            setHeaders({
                assistantId,
                threadId,
            });
          
            setAnalyzing(false);
            setShowHistory(true);

            const newTimestamp = Date.now();

            setHistory(({
                data: {
                    [newTimestamp]: {
                        inputs: inputs,
                        output: '',
                    }
                }
            }))
          
            const reader = response?.body?.getReader();
            if (!reader) {
                throw new Error('No reader available');
            }
          
            const decoder = new TextDecoder();
            let accumulatedText = '';
            setStreaming(true);
          
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                
                const chunk = decoder.decode(value);
            
                const messages = chunk.split('\n\n');
                
                for (const message of messages) {
                    if (message.startsWith('data: ')) {
                        const jsonStr = message.slice(6);
                        try {
                            const data = JSON.parse(jsonStr);
                            
                            accumulatedText += data.content;
                            setHistory(prevState => ({
                                data: {
                                    [newTimestamp]: {
                                        inputs: inputs,
                                        output: accumulatedText,
                                    }
                                },
                            }));
                        } catch (e) {
                            console.log('Error parsing message:', jsonStr, e);
                        }
                    } else if (message.startsWith('error: ')) {
                        console.log('Error message from stream:', message);
                    }
                }
            }
            setStreaming(false);
        } catch (error) {
            console.error('Error processing stream:', error);
        }

        // const { data, error } = res;

        // if (error) {
        //     if (retry) {
        //         handleSubmit({ retry: false });
        //         return;
        //     }

        //     const { err } = JSON.parse(error);
        //     logError(err);

        //     return;
        // }

        // const { analysis } = JSON.parse(data);
        // const { summary } = analysis;
        // if (summary) {
        //     setHistory(prevState => {
                // const dataObj = checkAndUpdateHistory(prevState.data, {
                //     inputs: inputs,
                //     output: summary,
                // });

    
                // const saveHistory = ({ retry = false }) => {
                //     supabase.from('user_preferences').upsert({
                //         ...prevState,
                //         data: dataObj,
                //         type: 'history',
                //         user_id: user?.id,
                //     }).eq('type', 'history').then((res) => {
                //         if (res?.error) {
                //             const error = new Error(res?.error?.message);
                //             logError(error.message, undefined, error);
        
                //             if (retry) {
                //                 saveHistory({ retry: false });
                //             }
                //         }
                //     });
                // };
    
                // saveHistory({ retry: true });
                // setAnalyzing(false);
                // setShowHistory(true);
                
                // return ({
                //     ...prevState,
                //     data: dataObj,
                //     // type: 'history',
                //     // user_id: user?.id,
                // });
        //     });
        // } else {
        //     setAnalyzing(false);
        //     setAnalysisFailed(true)
        //     setTimeout(() => {
        //         setAnalysisFailed(false);
        //     }, 3000);
        // }
    };
    const handleBack = () => {
        setInputs(DEFAULT_INPUTS);
        setSelectedWatchlist(null);
        setSelectedPortfolio(null);
        setStartAnalysis(true);
        navigate('/');
    };

    useEffect(() => {
        const retrievePerformance = async () => {
            const performance = await checkPerformance({
                start: inputs.date_start,
                end: inputs.date_end,
                symbols: portfolio ? Object.keys(inputs?.assets) : inputs?.assets
            });
            setPerformanceData(performance?.data);
        };

        if (inputs?.assets?.length || (portfolio && Object.keys(inputs?.assets)?.length) &&
            inputs?.date_start &&
            inputs?.date_end &&
            inputs?.date_end > inputs?.date_start
        ) {
            retrievePerformance();
        }
    }, [inputs?.assets, inputs?.date_start, inputs?.date_end]);

    useEffect(() => {
        setMissingAssets([]);
        (portfolio ? Object.keys(inputs?.assets) : inputs?.assets)?.forEach((asset, index) => {
            if (!AssetCheck({
                    assetKeys,
                    assetNames,
                    handleSubmit: () => {},
                    assetTickers,
                    inputVal: asset,
                })
            ) {
                setMissingAssets(prevState => [...prevState, asset]);
            }
        });
    }, [inputs?.assets])

    useEffect(() => {
        if (!(inputs?.date_start && inputs?.date_end)) {
            handleSelectPeriod({ target: { value: 'past-month' } });
        }
    }, []);

    return (
        <InputMenuContainer>
            <MenuContainer>
                <StyledButton onClick={handleBack} style={{ margin: 0, position: 'absolute', top: '16px', left: '16px' }}>
                    Back
                </StyledButton>
                <BlankSpacer/>
                <BlankSpacer/>
                <BlankSpacer/>
                {Array.isArray(children) ?
                    children?.map((child, index) => (
                        <MenuItem key={index} flexNumber={child?.props?.flexNumber}>
                            {child}
                        </MenuItem>
                    )) :
                    <MenuItem flexNumber={children?.props?.flexNumber}>
                        {children}
                    </MenuItem>
                }
                {inputs?.assets?.length || (portfolio && Object.keys(inputs?.assets)?.length) ?
                    <div style={{ width: '100%' }}>
                        <AssetView
                            style={{ borderRadius: missingAssets?.length ? '10px 10px 0 0' : null }}
                        >
                            {portfolio ?
                                Object.keys(inputs?.assets)?.filter(ticker => !missingAssets.includes(ticker))?.sort((a, b) => a.localeCompare(b))?.map((ticker, index) => {
                                    const totalWeight = Object.values(inputs?.assets).reduce((acc, weight) => acc + (parseFloat(weight) || 0), 0);
                                    return (
                                        <StyledListItem key={index} onClick={() => handleDeleteTicker(index)}>
                                            <CloseIcon/>&nbsp;
                                            <StyledValue>
                                                {ticker}
                                                {(inputs?.date_start && inputs.date_end) ? 
                                                    <>
                                                        :&nbsp;{round(performanceData?.filter(item => item.symbol === ticker)?.[0]?.period_return * 100, 1)}% /
                                                        {round((performanceData?.filter(item => item.symbol === ticker)?.[0]?.period_return) * ((parseFloat(inputs?.assets?.[ticker]) || 0) / totalWeight) * 100, 1)}%<WeightIcon/>
                                                    </> :
                                                    null
                                                }
                                            </StyledValue>
                                        </StyledListItem>
                                )}) :
                                inputs?.assets?.filter(ticker => !missingAssets.includes(ticker))?.sort((a, b) => a.localeCompare(b))?.map((ticker, index) => (
                                    <StyledListItem key={index} onClick={() => handleDeleteTicker(index)}>
                                        <CloseIcon/>&nbsp;
                                        <StyledValue>
                                            {ticker}
                                            {inputs?.date_start && inputs.date_end ?
                                                <>
                                                    :&nbsp;{performanceData?.filter(item => item.symbol === ticker)?.[0]?.period_return ? round(performanceData?.filter(item => item.symbol === ticker)?.[0]?.period_return * 100, 1) : '--' }%
                                                </> :
                                                null
                                            }
                                        </StyledValue>
                                    </StyledListItem>
                                ))
                            }
                        </AssetView>
                        {missingAssets?.length ?
                            <ExcludedAssets>
                                Assets Not Currently Covered by MarketReader:
                                <div>
                                    {missingAssets?.join(', ')}
                                </div>
                            </ExcludedAssets> :
                            null
                        }
                        {inputs?.date_start && inputs?.date_end &&
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                {portfolio ?
                                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                                            <StyledLabel style={{ margin: 0, marginRight: '5px', display: 'flex', alignItems: 'center' }}>
                                                Avg. Period Rtn:
                                                <StyledValue style={{ marginLeft: '5px', marginRight: '5pxpx' }}>{getAveragePeriodReturn(performanceData)}%</StyledValue>/
                                                Weighted Rtn:
                                                <StyledValue style={{ marginLeft: '5px' }}>{getWeightedReturn(performanceData, inputs?.assets)}%<WeightIcon/></StyledValue>
                                            </StyledLabel>
                                        </div> :
                                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                                            <StyledLabel style={{ margin: 0, marginRight: '5px' }}>
                                                Avg. Period Rtn:
                                            </StyledLabel>
                                            <StyledValue>
                                                {getAveragePeriodReturn(performanceData)}%
                                            </StyledValue>
                                        </div>
                                }
                                {!portfolio &&
                                    <CancelButton onClick={handleClearAssets} style={{ margin: '0px', marginBottom: 0 }}>
                                        <CloseIcon style={{ margin: 0 }}/>&nbsp;
                                        Clear All Assets
                                    </CancelButton>
                                }
                            </div>
                        }
                    </div> :
                    null
                }
                {inputs?.assets?.length || (portfolio && Object.keys(inputs?.assets)?.length) ?
                    <>
                        <MenuItem>
                            <DropdownMenu
                                id='period'
                                label={Periods.label}
                                handleChange={handleSelectPeriod}
                                options={Periods.values}
                                value={inputs.period}
                            />
                        </MenuItem>
                        <MenuItem flexNumber={2}>
                            <StyledLabel>
                                Start Date:
                            </StyledLabel>
                            <Input
                                min={new Date('15 February, 2023').toISOString().split('T')[0]}
                                max={new Date().toISOString().split('T')[0]}
                                onChange={handleStartDate}
                                style={{ marginRight: 0 }}
                                type='date'
                                value={inputs.date_start}
                            />
                        </MenuItem>
                        <MenuItem flexNumber={2}>
                            <StyledLabel>
                                End Date:
                            </StyledLabel>
                            <Input
                                min={inputs.date_start ?
                                    new Date(inputs.date_start).toISOString().split('T')[0] :
                                    new Date('15 February, 2023').toISOString().split('T')[0]
                                }
                                max={new Date().toISOString().split('T')[0]}
                                onChange={handleEndDate}
                                style={{ marginRight: 0 }}
                                type='date'
                                value={inputs.date_end}
                            />
                        </MenuItem>
                        <BlankSpacer/>
                        <MenuItem flexNumber={2}>
                            <StyledLabel>
                                Benchmark:
                            </StyledLabel>
                            <SearchDropdown
                                handleSubmit={handleBenchmark}
                                options={assets}
                                position='bottom'
                                dropdownContainerRef={menuContainerRef}
                            />
                            {inputs?.benchmark_asset &&
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                    <StyledLabel style={{ margin: '5px 10px' }}>
                                        Period Return:
                                    </StyledLabel>
                                    <StyledValue>
                                        10
                                    </StyledValue>
                                </div>
                            }
                        </MenuItem>
                        <MenuItem flexNumber={2}>
                            <MultiSelectDropdown
                                label='Macro and Sector Analysis'
                                handleChange={handleMacroSelection}
                                options={inputPacket?.macro_analysis?.values}
                                value={inputs?.macro_analysis ? inputs?.macro_analysis : []}
                                dropdownContainerRef={menuContainerRef}
                            />
                        </MenuItem>
                        <MenuItem flexNumber={2}>
                            <DropdownMenu
                                id='bias'
                                label={inputPacket?.bias?.label}
                                handleChange={handleSelectBias}
                                options={inputPacket?.bias?.values}
                                value={inputs?.bias}
                            />
                        </MenuItem>
                        <MenuItem flexNumber={2}>
                            <DropdownMenu
                                id='format'
                                label={inputPacket?.output_length?.label}
                                handleChange={handleSelectFormat}
                                options={inputPacket?.output_length?.values}
                                value={inputs?.output_length}
                            />
                        </MenuItem>
                        <BlankSpacer />
                    </> :
                    null
                }
            </MenuContainer>
            <SubmitButton
                disabled={(portfolio ? !Object.keys(inputs?.assets)?.length : !inputs?.assets?.length) || !inputs?.date_start || !inputs?.date_end}
                onClick={() => handleSubmit({ retry: true })}
                style={{ width: '60%', marginTop: '10px' }}
            >
                Analyze
            </SubmitButton> 
        </InputMenuContainer>
    );
};

export default InputMenu;
