import { useState, useEffect, useCallback } from 'react';
import supabase from '../services/supabaseClient';

const useFetchEtfsWithHoldings = ({ logError, setShowErrorWindow }) => {
    const [etfsWithHoldings, setEtfsWithHoldings] = useState([])
    const [etfsLoading, setEtfsLoading] = useState(true);

    const fetchAllAssets = useCallback(async ({ retry = true }) => {     
        const res = await supabase.from('etfs_w_holdings').select('*');

        if (res?.error) {
            const error = new Error(res?.error?.message);
            logError(error.message, undefined, error);

            if (retry) {
                fetchAllAssets({ retry: false });

                return;
            }
    
            setShowErrorWindow(true);

            return;
        }

        const etfsWithHoldings = res?.data?.map(item => item.symbol);

        setEtfsWithHoldings(etfsWithHoldings);
        setEtfsLoading(false);
    }, []);

    useEffect(() => {
        fetchAllAssets({ retry: true });
    }, []);

    return {
        etfsLoading,
        etfsWithHoldings,
    };
};

export default useFetchEtfsWithHoldings;
