import { useState, useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { AppColors } from '../StyledComponents/Graphics';
import { Input, WarningText } from '../StyledComponents/UserFormStyledComponents';
import AssetCheck from '../../Controllers/utils/assetCheck';
import { StateContext } from '../../Controllers/contexts/StateContext';

const SearchWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: ${props => props.open ? '200px' : null};
`;

const SearchBox = styled(Input)`
    padding: 10px 4px 10px 40px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
`;

const DropdownMenu = styled.div`
    background: ${AppColors.MRWHITE};
    color: ${AppColors.MRBLACK};
    border: 1px solid ${AppColors.MRGREY70};
    border-radius: 10px;
    box-sizing: border-box;
    max-height: 200px;
    height: ${({ height }) => height && height < 200 ? `${height}px` : 'auto'};
    overflow: auto;
    position: absolute;
    width: 100%;
    z-index: 999;
`;

const DropdownItem = styled.div`
    padding: 5px 10px;

    &:hover {
        background: ${AppColors.MRBLUE};
        color: ${AppColors.MRNAVY20};
    }
`;

const Tooltip = styled(WarningText)`
    position: absolute;
    width: fit-content;
    top: ${props => props.position === 'top' ? '5px' : props.position === 'bottom' ? 'calc(100% + 8px)' : '50%'};
    left: ${props => props.position === 'left' ? '-80px' : props.position === 'right' ? 'calc(100% + 10px)' : '50%'};
    transform: ${props => props.position === 'top' || props.position === 'bottom' ? 'translateX(-50%)' : 'translateY(-50%)'};
    margin-top: ${props => props.position === 'top' ? '-40px' : '0'};
    margin-bottom: ${props => props.position === 'bottom' ? '10px' : '0'};
    display: flex;
    align-items: center;
    background-color: ${AppColors.MRRED};
    color: ${AppColors.MRNAVY20};
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 999;

    &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        ${props => {
            if (props.position === 'top') {
                return `
                    bottom: -6px;
                    left: 50%;
                    transform: translateX(-50%);
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 6px solid ${AppColors.MRRED};
                `;
            } else if (props.position === 'bottom') {
                return `
                    top: -6px;
                    left: 50%;
                    transform: translateX(-50%);
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-bottom: 6px solid ${AppColors.MRRED};
                `;
            } else if (props.position === 'right') {
                return `
                    top: 50%;
                    left: -8px;
                    transform: translateY(-50%);
                    border-top: 6px solid transparent;
                    border-bottom: 6px solid transparent;
                    border-right: 8px solid ${AppColors.MRRED};
                `;
            } else {
                return `
                    top: 50%;
                    right: -8px;
                    transform: translateY(-50%);
                    border-top: 6px solid transparent;
                    border-bottom: 6px solid transparent;
                    border-left: 8px solid ${AppColors.MRRED};
                `;
            }
        }}
    }
`;

const SearchDropdown = ({
    clearSearch,
    handleSubmit,
    handleTickerChange,
    options,
    padded,
    placeholder,
    position,
    searchRef,
    setClearSearch,
    dropdownContainerRef
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [inputVal, setInputVal] = useState('');
    const [searchText, setSearchText] = useState('');
    const [validTicker, setValidTicker] = useState(true);
    const wrapperRef = useRef(null);
    const {
        assetKeys,
        assetNames,
        assetTickers,
    } = useContext(StateContext);
    const searchBoxRef = useRef(null);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.target.select()
            AssetCheck({
                assetKeys,
                assetNames,
                assetTickers,
                handleSubmit,
                inputVal,
                setDropdownOpen,
                setValidTicker,
            });
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    const handleChange = event => {
        handleTickerChange && handleTickerChange(event.target.value);
        setValidTicker(true);
        setSearchText(event.target.value);
        setInputVal(event.target.value);
        setDropdownOpen(true);
    };

    const handleFocus = () => {
        setDropdownOpen(true);
        setClearSearch && setClearSearch(false);
    };

    const handleSelect = value => {
        handleTickerChange && handleTickerChange(value);
        handleSubmit(value);
        setSearchText(value);
        setInputVal(value);
        if (searchRef) searchRef.current.select();
        setDropdownOpen(false);
    }

    const filteredOptions = options?.filter(option =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
    );

    const prioritizeBestFit = (a, b) => {
        const aValue = a.label.toLowerCase();
        const bValue = b.label.toLowerCase();
        const searchTextLower = searchText.toLowerCase();
    
        if (aValue.startsWith(searchTextLower) && !bValue.startsWith(searchTextLower)) {
            return -1;
        }
    
        if (!aValue.startsWith(searchTextLower) && bValue.startsWith(searchTextLower)) {
            return 1;
        }
    
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
    };

    useEffect(() => {
        if (clearSearch) {
            setSearchText('');
        }
    }, [clearSearch]);

    return (
        <SearchWrapper ref={wrapperRef} open={dropdownOpen && padded}>
            <SearchBox
                onChange={handleChange}
                onFocus={handleFocus}
                onClick={() => !dropdownOpen ? setDropdownOpen(true) : null}
                onKeyDown={handleKeyDown}
                placeholder={placeholder || "Search Assets..."}
                ref={searchRef || searchBoxRef}
                type="search"
                value={searchText}
            />
            {!validTicker && (
                <Tooltip position={position}>
                    Unknown
                </Tooltip>
            )}
            {dropdownOpen && (
                <DropdownMenu
                    height={dropdownContainerRef ? dropdownContainerRef?.current?.getBoundingClientRect().bottom - searchBoxRef?.current?.getBoundingClientRect().bottom : null}
                >
                    {filteredOptions?.sort(prioritizeBestFit).map(option => (
                        <DropdownItem
                            key={option.value}
                            onClick={() => handleSelect(option.value)}
                        >
                            {option.label}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            )}
        </SearchWrapper>
    );
};

export default SearchDropdown;
