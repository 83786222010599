import styled from 'styled-components';

export const AppColors = {
    MRBLACK: 'black',
    BLUE: '#0052CC',
    MRBLUE: '#31BCED',
    MRDARKGREY: '#363737',
    MRGOLD: '#FBC227',
    MRGREEN: '#5DD39E',
    MRGREY:'#485662',
    MRGREY70: '#8B9EAD',
    MRLIGHTGREY: '#DFE3E6',
    MRLITE90: '#D5DBE0',
    MRLITE80:'#B3BEC7', 
    MRNAVY: '#0E202F',
    MRNAVY15:'#0A1926',
    MRNAVY18: '#0B1E2E',
    MRNAVY20:'#0D2233',
    MRNAVY30:'#193247',
    MRNAVY40:'#2C4961',
    MRNAVY50:'#43617A',
    MRNAVY60:'#607C94',
    MRNAVYLITE: '#183247',
    MRORANGE: '#F98848',
    MRRED: '#EE6352',
    MRVIOLET: '#5F2BFF',
    MRWHITE: '#F2F3F4',
    NEUTRAL: 'linear-gradient(130deg, rgba(134, 151, 166, 0.2) 30%, rgba(134, 151, 166, 0.3) 65%, rgba(134, 151, 166, 0.5) 98%)',
}

export const AppGradients = {
    MRNAVYGREEN: 'linear-gradient(90deg, #183247 20%, #5DD39E 90%)',
    MRGREENORANGE: 'linear-gradient(90deg, #5DD39E 10%, #F98848 95%);',
    MRFADED: 'linear-gradient(90deg, #183247 50%, #5DD39E 90%)',
}

export const CardColors = {
    ALPH: 'linear-gradient(130deg, rgba(93, 211, 158, 0.2) 20%, rgba(93, 211, 158, 0.5) 65%, rgba(93, 211, 158, 0.9) 98%)',
    BETA: 'linear-gradient(130deg, rgba(122, 211, 171, 0.2) 20%, rgba(122, 211, 171, 0.4) 65%, rgba(122, 211, 171, 0.7) 98%)',
    GAMM: 'linear-gradient(130deg, rgba(180, 210, 197, 0.2) 20%, rgba(180, 210, 197, 0.4) 65%, rgba(180, 210, 197, 0.7) 98%)',

    DELT: 'linear-gradient(130deg, rgba(205, 222, 214, 0.2) 30%, rgba(205, 222, 214, 0.3) 65%, rgba(205, 222, 214, 0.5) 98%)',
    EPIS: 'linear-gradient(130deg, rgba(134, 151, 166, 0.2) 30%, rgba(134, 151, 166, 0.3) 65%, rgba(134, 151, 166, 0.5) 98%)',
    IOTA: 'linear-gradient(130deg, rgba(229, 205, 202, 0.2) 30%, rgba(229, 205, 202, 0.3) 65%, rgba(229, 205, 202, 0.5) 98%)',

    KAPP: 'linear-gradient(130deg, rgba(224, 176, 170, 0.2) 20%, rgba(224, 176, 170, 0.4) 65%, rgba(224, 176, 170, 0.7) 98%)',
    LAMB: 'linear-gradient(130deg, rgba(242, 144, 132, 0.2) 20%, rgba(242, 144, 132, 0.4) 65%, rgba(242, 144, 132, 0.7) 98%)',
    ZETA: 'linear-gradient(130deg, rgba(238, 99, 82, 0.3) 20%, rgba(238, 99, 82, 0.5) 65%, rgba(238, 99, 82, 0.9) 98%)',
}

export const BarModule = styled.div` 
    width: 100%;
    padding: 0px 20px;
    color: ${AppColors.MRLITE80};
    font-size: 16px;
    display: flex;
    align-items: center; 
    justify-content: space-between;
    border-bottom: 2px solid black;
`;

export const GreenOrangeBar = styled(BarModule)` 
    height: 4px;
    padding: 0;
    background: ${AppGradients.MRGREENORANGE}; 
`;

export const NavyBar = styled(BarModule)` 
    position: relative;
    background: ${AppColors.MRNAVY20};  
`;

export const NavyGreenBar = styled(BarModule)` 
    background: ${AppGradients.MRNAVYGREEN};
    padding: 0 0 0 20px;
`;

export const TagColors = {
    DTM: 'rgba(255, 255, 255, 0.25)',
    TEN: 'rgba(255, 255, 255, 0.5)',
    GREEN: 'rgba(93, 211, 158, 1)',
    RED: 'rgba(238, 99, 82, 1)',
    PURP: 'rgba(140, 96, 255, 0.75)',
 };