import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { AppColors } from './Graphics';

export const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${props => (props.isMobile ? null : '100vh')};
    width: 100vw;
    background-color: ${AppColors.MRWHITE};
`;

export const SignUpContainer = styled.div`
     position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100vw;
    background-color: ${AppColors.MRWHITE};
    padding: ${props => props.isMobile ? '90px 0' : null};
`;

export const Form = styled.form`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
`;

export const FormWrapper = styled.div`
    color: ${AppColors.MRWHITE};
    width: ${props => (props.isMobile ? 'calc(100% - 2rem)' : '400px')};
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${AppColors.MRWHITE};
    padding: 2rem;
    border-radius: 10px;
`;

export const Heading = styled.h2`
    color: ${AppColors.MRNAVY};
    text-align: center;
    font-family: Mulish, Arial, Helvetica, sans-serif;
    font-weight: 800;
`;

export const Input = styled.input`
  color: ${AppColors.MRNAVY};
  background-color: ${AppColors.MRWHITE};
  border: 1px solid ${AppColors.MRGREY70};
  border-radius: 10px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 10px 12px;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;

  &:focus {
    border-color: ${AppColors.MRNAVY50};
    box-shadow: 0 0 3px rgba(26, 115, 232, 0.5);
  }
`;

export const InputWrapper = styled(FormWrapper)`
    padding: 2px;
    width: 200px;
`;

export const MobileFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${props => props.isMobile ? '90%' : '40%'};
    height: ${props => props.isMobile || props.code ? 'auto' : '100vh'};
    background-color: ${props => props.isMobile ? null : AppColors.MRNAVY15};
`;

export const SearchArea = styled.div`
    margin-right: 20px;
    margin-top: 2px;
    width: 350px;
`;

export const StyledButton = styled.button`
    background: none;
    border: 1px solid ${AppColors.MRNAVY40};
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: ${props => props.disabled ? 'grey' : AppColors.MRNAVY40};
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin: 10px auto;
    color: ${AppColors.MRWHITE};

    &:focus, &:hover {
        background-color: ${props => props.disabled ? 'grey' : AppColors.MRNAVY20};
    }

    svg {
        margin-left: 10px;
        font-size: 14px;
    }
`;

export const CancelButton = styled(StyledButton)`
    background: ${AppColors.MRRED};
    color: ${AppColors.MRNAVY15};
    margin: 5px;

    &:focus, &:hover {
        background-color: ${props => props.disabled ? 'grey' : AppColors.MRRED};
        border: 3px solid ${AppColors.MRNAVY40};
        border-radius: 10px;
    }
`;

export const CheckboxItem = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background: ${props => props.checked ? AppColors.MRBLUE : AppColors.MRNAVY20};
    color: ${(props) => props.checked ? AppColors.MRNAVY20 : AppColors.MRWHITE};
    border: 1px solid ${AppColors.MRNAVY60};
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
        background: ${AppColors.MRNAVY60};
        color: ${AppColors.MRNAVY20};
    }
`;

export const CheckboxLabel = styled.label`
    margin-left: 10px;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
`;

export const ConfirmationButton = styled(StyledButton)`
    background: ${props => props.disabled ? 'grey' : AppColors.MRBLUE};
    color: ${AppColors.MRNAVY15};
    margin: 5px;
`;

export const StyledHyperLink = styled.a`
    color: ${AppColors.MRBLUE};
`;

export const StyledLabelInline = styled.p`
    margin: 0 5px;
    white-space: nowrap;
`;

export const StyledLabel = styled.p`
    margin: 10px 0px 2px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    color: ${AppColors.MRGREY};
    font-weight: bold;
`;

export const StyledLink = styled(Link)`
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    color: ${AppColors.MRBLACK};
    font-family: inherit;
    font-weight: bold;
`;


export const StyledSelect = styled.select`
    width: 100%;
    cursor: pointer;
    border: 1px solid;
    border-color: ${AppColors.MRGREY70};
    border-radius: 10px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 10px 12px;
    font-size: 16px;
    background-color: ${AppColors.MRWHITE};
    color: ${AppColors.MRNAVY};

    option {
        background-color: ${AppColors.MRWHITE};
        color: ${AppColors.MRNAVY};
    }
`;

export const SubmitButton = styled.button`
    width: 95%;
    background-color: ${props => (props.disabled ? 'grey' : AppColors.MRGREEN)};
    color: ${AppColors.MRNAVY};
    border: none;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    transition: background-color 0.2s;
    text-transform: uppercase;
    text-align: center;
    justify-content: center;

    &:hover {
        opacity: 0.5;
    }
`;

export const SuccessMessage = styled.div`
    font-size: 16px;
    color: darkgrey;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
`;

export const TextArea = styled.textarea`
    height: 41px;
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    border: 1px solid;
    border-color: ${AppColors.MRGREY70};
    border-radius: 10px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 10px 12px;
    font-size: 16px;
    background-color: ${AppColors.MRWHITE};
    color: ${AppColors.MRNAVY};
`;

export const WarningText = styled.div`
    display: flex;
    color: ${AppColors.MRRED};
    margin: 0 auto;
    width: 90%;
    align-items: center;
    justify-content: center;
    text-align: center;
`;
