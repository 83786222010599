const formatTime = (inputTime) => {
    const date = new Date(inputTime);

    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = date.getHours() >= 12? "PM" : "AM";


    const outputTime = `${hours}:${minutes}${ampm}`;

    return outputTime;
};

export const identicalObjects = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export default formatTime;
