import { useContext, useEffect, useState } from 'react';
import { StateContext } from "../../../Controllers/contexts/StateContext";
import { ConfirmationButton, Heading, StyledButton, WarningText } from '../../../Global/StyledComponents/UserFormStyledComponents';
import styled from "styled-components";
import { CloseIcon } from "../../../Global/Icons/Icons";
import SearchDropdown from "../../../Global/Components/SearchDropdown";
import { equalArrays } from "../../../Controllers/utils";
import { useUndo } from '../../../Controllers/contexts/UndoContext';
import UndoButton from '../../../Global/Components/UndoButton';
import AssetCheck from '../../../Controllers/utils/assetCheck';
import supabase from '../../../Controllers/services/supabaseClient';

const StyledList = styled.ul`
    columns: ${props => Math.min(Math.floor(props.total / 2), 5)};
    column-gap: 40px;
    list-style-type: none;
`;

const StyledListItem = styled.li`
    padding: 5px;
    display: flex;
    align-items: center;
`;

const WatchlistViewer = () => {
    const { addAction, state: undoState } = useUndo();
    const {
        assets,
        assetKeys,
        assetNames,
        assetTickers,
        fetchAllWatchlists,
        logError,
        selectedWatchlist,
        setInputs,
        setSelectedWatchlist,
        setShowErrorWindow,
        setShowWatchlistViewer,
    } = useContext(StateContext);
    const [error, setError] = useState(false);
    const [newTicker, setNewTicker] = useState(null);
    const [updated, setUpdated] = useState(false);
    const [validTicker, setValidTicker] = useState(true);
    const [watchlistTickers, setWatchlistTickers] = useState(() => selectedWatchlist?.data || []);

    const fetchWatchlist = ({ retry = true }) => {
        supabase.from('user_preferences').select('*').eq('id', selectedWatchlist?.id).then((res) => {
            if (res?.error) {
                const error = new Error(res?.error?.message);
                logError(error.message, undefined, error);
    
                if (retry) {
                    fetchWatchlist({ retry: false });
    
                    return;
                }
        
                setShowErrorWindow(true);
    
                return;
            }

            setSelectedWatchlist(res?.data[0]);
        });
    };

    const handleDeleteTicker = (id) => {
        const index = parseInt(id);
        const deletedTicker = watchlistTickers[index];
    
        setWatchlistTickers(prevState => prevState.filter((_, idx) => idx !== index));
        addAction({ type: 'DELETE', index, ticker: deletedTicker });
    };

    const handleAddTicker = (inputVal) => {
        const handleSubmit = (value) => {
            setWatchlistTickers(prevState => [...prevState, value.toUpperCase()]);
            addAction({ type: 'ADD', ticker: value });
        };

        AssetCheck({
            assetKeys,
            assetNames,
            assetTickers,
            handleSubmit,
            inputVal,
            setValidTicker,
        });
    };

    const handleTickerChange = (value) => {
        setValidTicker(true);
        setNewTicker(value);
    };

    const handleUpdateWatchlist = () => {
        const updatedWatchlist = {
            data: [
                ...watchlistTickers.filter(ticker => !selectedWatchlist?.data?.includes(ticker)),
                ...selectedWatchlist?.data
            ],
            id: selectedWatchlist?.id,
            meta: selectedWatchlist?.meta,
            user_id: selectedWatchlist?.user_id,
        };

        supabase.from('user_preferences').update(updatedWatchlist)
            .eq('id', selectedWatchlist?.id).then((res) => {
                if (res?.error) {
                    setError(true);
                } else {
                    fetchWatchlist({ retry: true });
                    fetchAllWatchlists({ retry: true });
                    setError(false);
                }
            });
    };

    const handleUseWatchlist = () => {
        setInputs(prevState => ({
            ...prevState,
            assets: watchlistTickers,
        }));

        setShowWatchlistViewer(false);
    };

    useEffect(() => {
        if (undoState.undoing) {
            const { type, index, ticker } = undoState.undoing;
            if (type === 'DELETE') {
                setWatchlistTickers(prevState => {
                    const newTickers = [...prevState];
                    newTickers.splice(index, 0, ticker);
                    return newTickers;
                });
            } else if (type === 'ADD') {
                setWatchlistTickers(prevState => prevState.filter(t => t !== ticker));
            }
        }
    }, [undoState.undoing]);

    useEffect(() => {
        setUpdated(!equalArrays(watchlistTickers, selectedWatchlist.data));
    }, [watchlistTickers, selectedWatchlist.data]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Heading>{selectedWatchlist?.meta?.name}</Heading>
            <StyledList total={watchlistTickers.length}>
                {watchlistTickers?.map((ticker, index) => (
                    <StyledListItem key={index}>
                        <CloseIcon onClick={() => handleDeleteTicker(index)}/>&nbsp;{ticker}
                    </StyledListItem>
                ))}
            </StyledList>
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 50 }}>
                <div>
                    <SearchDropdown
                        handleSubmit={handleAddTicker}
                        options={assets}
                        padded
                        position='right'
                        handleTickerChange={handleTickerChange}
                    />
                    {!validTicker &&
                        <WarningText>
                            Invalid Ticker
                        </WarningText>
                    }
                </div>
                <StyledButton onClick={() => handleAddTicker(newTicker)}>
                    Add
                </StyledButton>
            </div>
            <div style={{ alignItems: 'center', display: 'flex', position: 'absolute', bottom: 10, right: 10 }}>
                <UndoButton />
                {updated &&
                    <StyledButton onClick={handleUpdateWatchlist}>
                        Update Watchlist
                    </StyledButton>
                }
                <ConfirmationButton onClick={handleUseWatchlist}>
                    Use For Analysis
                </ConfirmationButton>
                {error &&
                    <WarningText style={{ textAlign: 'center' }}>
                        Failed to Update Watchlist
                    </WarningText>
                }
            </div>
        </div>
    );
};

export default WatchlistViewer;
