import { useState, useEffect, useCallback } from 'react';
import supabase from '../services/supabaseClient';

const useFetchAssets = ({ logError, setShowErrorWindow }) => {
    const [assets, setAssets] = useState([]);
    const [assetKeys, setAssetKeys] = useState({});
    const [assetNames, setAssetNames] = useState([]);
    const [assetTickers, setAssetTickers] = useState([]);
    const [assetsLoading, setAssetsLoading] = useState(true);
    const [etfAssets, setEtfAssets] = useState([]);

    const fetchAllAssets = useCallback(async ({ retry = true }) => {     
        const res = await supabase.from('symbols').select('*');

        if (res?.error) {
            const error = new Error(res?.error?.message);
            logError(error.message, undefined, error);

            if (retry) {
                fetchAllAssets({ retry: false });

                return;
            }
    
            setShowErrorWindow(true);

            return;
        }

        const data = res?.data;
        const objectsForSearch = data?.map(item => ({
            ...item,
            label: `${item.symbol} - ${item.name}`,
            value: item.symbol,
        }));

        const keys = {};
        data?.forEach((item) => keys[item.name?.toLowerCase()] = item.symbol);
        const names = data?.map(item => item.name?.toLowerCase());
        const tickers = data?.map(item => item.symbol);
        const etfs = data?.filter(item => item.type === 'ETP')?.map(item => ({
            ...item,
            label: `${item.symbol} - ${item.name}`,
            value: item.symbol,
        }));
        
        setAssets(objectsForSearch);
        setAssetKeys(keys);
        setAssetNames(names);
        setAssetTickers(tickers);
        setEtfAssets(etfs);
        setAssetsLoading(false);
    }, []);

    useEffect(() => {
            fetchAllAssets({ retry: true });
    }, []);

    return {
        assets,
        assetKeys,
        assetNames,
        assetsLoading,
        assetTickers,
        etfAssets,
    };
};

export default useFetchAssets;
