import { createContext, useEffect, useState } from 'react';
import useFetchAssets from '../hooks/useFetchAssets';
import useFetchWatchlists from '../hooks/useFetchWatchlists';
import useFetchPortfolios from '../hooks/useFetchPortfolios';
import useFetchInputPacket from '../hooks/useFetchInputPacket';
import useFetchHistory from '../hooks/useFetchHistory';
import useFetchEtfsWithHoldings from '../hooks/useFetchEtfsWithHoldings';
import { DEFAULT_INPUTS } from '../../Global/constants';
import useFetchMacroSummary from '../hooks/useFetchMacroSummary';

export const StateContext = createContext();

export const StateProvider = ({ children, values }) => {
    const logError = (message, context, error) => { console.log(message, context, error) };

    const [analysisFailed, setAnalysisFailed] = useState(false);
    const [analyzing, setAnalyzing] = useState(false);
    const [analyzingChat, setAnalyzingChat] = useState(false);
    const development = ['dev', 'localhost'].some((env) =>
		window.location.href.includes(env)
	);
    const [headers, setHeaders] = useState({});
    const [history, setHistory] = useState({ data: {} });
    const [inputs, setInputs] = useState(DEFAULT_INPUTS);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingDots, setLoadingDots] = useState('');
    const [messages, setMessages] = useState([]);
    const [selectedPortfolio, setSelectedPortfolio] = useState(null);
    const [selectedWatchlist, setSelectedWatchlist] = useState(null);
    const [sideBarOpen, setSideBarOpen] = useState(true);
    const [showErrorWindow, setShowErrorWindow] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [showPortfolioManager, setShowPortfolioManager] = useState(false);
    const [showWatchlistViewer, setShowWatchlistViewer] = useState(false);
    const [startAnalysis, setStartAnalysis] = useState(false);
    const [streaming, setStreaming] = useState(false);
    const [translationFailed, setTranslationFailed] = useState(false);

    const {
        assets,
        assetKeys,
        assetNames,
        assetsLoading,
        assetTickers,
        etfAssets,
    } = useFetchAssets({ logError, setShowErrorWindow });

    const {
        etfsLoading,
        etfsWithHoldings,
    } = useFetchEtfsWithHoldings({ logError, setShowErrorWindow });

    // const {
    //     history,
    //     historyLoading,
    //     setHistory,        
    // } = useFetchHistory({ logError, setShowErrorWindow });

    const {
        inputPacket,
        inputPacketLoading,
    } = useFetchInputPacket({ logError, setShowErrorWindow })

    const {
        macroSummary,
        macroSummaryLoading,
    } = useFetchMacroSummary({ logError, setShowErrorWindow });

    const {
        fetchAllPortfolios,
        portfolios,
        portfoliosLoading,
    } = useFetchPortfolios({ logError, setShowErrorWindow });

    const {
        fetchAllWatchlists,
        watchlists,
        watchlistsLoading,
    } = useFetchWatchlists({ logError, setShowErrorWindow });

    useEffect(() => {
		if (
			!assetsLoading &&
            !etfsLoading &&
            !macroSummaryLoading &&
            !portfoliosLoading &&
            !watchlistsLoading
		) {
			setIsLoading(false);
		}
	}, [
		assetsLoading,
        etfsLoading,
        macroSummaryLoading,
        portfoliosLoading,
        watchlistsLoading,
	]);

    const state = {
        analysisFailed,
        analyzing,
        analyzingChat,
        assets,
        assetsLoading,
        assetKeys,
        assetNames,
        assetsLoading,
        assetTickers,
        development,
        etfAssets,
        etfsLoading,
        etfsWithHoldings,
        fetchAllPortfolios,
        fetchAllWatchlists,
        headers,
        history,
        inputPacket,
        inputPacketLoading,
        inputs,
        isLoading,
        logError,
        loadingDots,
        macroSummary,
        macroSummaryLoading,
        messages,
        portfolios,
        portfoliosLoading,
        selectedPortfolio,
        selectedWatchlist,
        setAnalysisFailed,
        setAnalyzing,
        setAnalyzingChat,
        setHeaders,
        setHistory,
        setInputs,
        setLoadingDots,
        setMessages,
        setSelectedPortfolio,
        setSelectedWatchlist,
        setShowHistory,
        setShowPortfolioManager,
        setShowWatchlistViewer,
        setSideBarOpen,
        setStreaming,
        setStartAnalysis,
        setTranslationFailed,
        showErrorWindow,
        showHistory,
        showPortfolioManager,
        showWatchlistViewer,
        sideBarOpen,
        startAnalysis,
        streaming,
        translationFailed,
        ...values,
        watchlists,
        watchlistsLoading,
    };
    
    return (
        <StateContext.Provider value={state}>
            {children}
        </StateContext.Provider>
    );
};
