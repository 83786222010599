import { useEffect, useMemo, useState } from 'react';

export default function useOnScreen(ref, threshold = 0.1) {
    const [isIntersecting, setIntersecting] = useState(false);
  
    const observer = useMemo(() => 
        new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting),
            {
                rootMargin: '0px',
                threshold: threshold,
            }
        ), 
        [ref, threshold]
    );
  
    useEffect(() => {
        if (ref.current) {
            observer.observe(ref.current);
            return () => observer.disconnect();
        }
    }, [observer, ref]);
  
    return isIntersecting;
};