import React, { createContext, useContext, useReducer } from 'react';

const UndoContext = createContext();

const undoReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_ACTION':
            return {
                ...state,
                history: [...state.history, action.payload],
            };
        case 'UNDO':
            const lastAction = state.history[state.history.length - 1];
            return {
                ...state,
                history: state.history.slice(0, -1),
                undoing: lastAction,
            };
        case 'CLEAR_UNDOING':
            return {
                ...state,
                undoing: null,
            };
        default:
            return state;
    }
};

export const UndoProvider = ({ children }) => {
    const [state, dispatch] = useReducer(undoReducer, { history: [], undoing: null });

    const addAction = (action) => {
        dispatch({ type: 'ADD_ACTION', payload: action });
    };

    const undo = () => {
        dispatch({ type: 'UNDO' });
    };


    return (
        <UndoContext.Provider value={{ state, addAction, undo }}>
            {children}
        </UndoContext.Provider>
    );
};

export const useUndo = () => useContext(UndoContext);
