import { isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';

import { AppColors } from './Graphics';

export const AllColumns = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    overflow-Y: scroll;
    
    h1 {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 30px;
    }

    p {
        margin: 10px 20px;
    }

    iframe {
        width: 100%;
        height: 100% !important; 
    }
    
`;

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const AppFrame = styled.div`
    position: relative;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

export const BlankLoadingScreen = styled.div`
    background: #1e1e1e;
    height: 100vh;
    width: 100vw;
`;

export const BlankSpacer = styled.div`
    width: 100%;
    height: 2px;
    margin-top: 10px;
    display: block;
`;

export const CardGridModalWrapper = styled.div`
    margin-top: 15px; 
    padding-bottom: 20px; 
    position: relative;
    height: 69vh;
`;

export const CenteredDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    padding: 0 5px;
    position: relative;
`;

export const ChartContainer = styled.div`
    width: 100%;
    overflow: visible !important;
`;

export const ClickableHeading = styled.div` 
    border: 1px solid ${AppColors.MRNAVY40};
    background: ${AppColors.MRNAVY20};
    border-radius: 0px 0px 10px 10px;
    margin-bottom: 5px;
    padding: 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;

    h1 {
        text-align: left;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        margin: 0px;
        padding-top: 5px;
        text-transform: uppercase;
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
    }

   h2 {
        text-align: left;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        margin: 0px;
        font-family: Arial, Helvetica, sans-serif;
    }
`;

export const Body = styled.div`
  display: flex;
  width: 100vw;
  height: calc(100vh - 83px);
`;

export const CTAButton = styled.button`
    display: block;
    width: 100%;    
    height: 40px;
    cursor: pointer;
    padding: 10px 30px;
    border-radius: 10px;
    border: 1px solid ${AppColors.MRNAVY40};
    background-color: ${AppColors.MRNAVY20};
    color: ${AppColors.MRWHITE};

    display: block;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;

    &:focus, &:hover {
        background-color: ${AppColors.MRNAVY40};
    }
    
    h2 {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        margin: 0px;
        text-transform: uppercase;
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
    }
`;

export const CTAButtonTitle = styled.h2`
    display: block;
    width: 100%;
    height: 40px;
    cursor: pointer;
    padding: 10px 30px;
    border: 1px solid ${AppColors.MRNAVY40};
    background-color: ${AppColors.MRNAVY20};
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: ${AppColors.MRWHITE};
    margin: 0px;
    text-transform: uppercase;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
`;

export const DataOutputContainer = styled.div`
    display: block;
    padding: 0px;
    content: 'DataOutput Container';
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
        overflow: hidden;
    }
`;

export const ExpandedBox = styled.div`
   color: ${AppColors.MRWHITE};
   background: ${AppColors.MRNAVY20};
   position: relative;
   width: 100%;
   height: auto;
   padding: 10px 10px 10px 10px;
   border: 1px solid ${AppColors.MRNAVY40};

   .center {
        text-align: center;
   }
`;

export const FeedButton = styled(CTAButtonTitle)`
    background-color: ${props => props.open ?  AppColors.MRNAVY50 : AppColors.MRNAVY20};
`;

export const FeedItem = styled.div`
    width: 100%;
    cursor: pointer;
    display: flex;
    margin: 0px;
    padding: 10px;
`;

export const FilterContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    padding: 10px;
    position: relative;
`;

export const FooterModuleContainer = styled.footer` 
    display: flex;
    width: 100vw;
    padding: 5px;
    color: ${AppColors.MRBLACK};
    font-size: 12px;
    font-family: Mulish, sans-serif; 
    background: ${AppColors.MRWHITE}; 
    bottom: 0;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    
    
    a {
        color: ${AppColors.MRGREEN};
        text-decoration: underline;
    }

    @media (max-width: 1020px) {
        font-size: 11px;

        a {
            font-size: 11px;
        }
    }
    @media (max-width: 955px) {
        font-size: 10px;

        a {
            font-size: 10px;
        }
    }
    @media (max-width: 955px) {
        font-size: 9px;

        a {
            font-size: 9px;
        }
    }
`;

export const ModalContainer = styled.div`
    width: ${(props) => props.width ? props.width : (!isMobile || isTablet) ? '80%' : '90%'};
    background: ${AppColors.MRNAVY18};
    background-image: ${props => props.image ? "url('/img/BackgroundImage.svg')" : null};
    color: ${AppColors.MRLITE80};
    padding: 10px;
    border-radius: 10px;
    max-height: 75vh;
    overflow: auto;
`;

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9199;
`;
    
export const MonitorContainer = styled.main`
    display: flex;
    justify-content: space-between;
    content: "Each Monitor";
    overflow: hidden;
    height: ${props => (!isMobile || isTablet) ? props.height - 96 : props.height - 76}px;
    position: ${(!isMobile || isTablet) ? 'relative' : 'absolute'};
    top: ${(!isMobile || isTablet) ? null : '38px'};
`;

export const Spacer = styled(BlankSpacer)`
    background: ${AppColors.MRNAVY40};
`;

export const Line = styled(Spacer)`
    height: 1px;
    margin-top: 0px;
`;

export const StockCardGrid = styled.div`
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-content: start;
   gap: 0px;
   overflow-Y: scroll;
   content: 'StockCard Grid';
   height: 100%;

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
        overflow: hidden;
    }
`;

export const Timestamp = styled.span`
    color: ${AppColors.MRLITE80};
`;

export const TrackingModuleContainer = styled.header`
    position: sticky; 
    width: 100vw;
    top: 0px; 
    z-index: 9999;
`;

export const ViewModule = styled.div`
    content: 'ViewModule';
    background-color: ${AppColors.MRNAVY18};
    border-radius: 10px ;
    display: flex;
    flex-direction: column;
    padding: ${(!isMobile || isTablet) ? '20px 46px' : '20px 10px'};
    position: relative;
    
    @media (min-width: 768px) {
        width: calc(100vw);
    }
    @media (min-width: 1080px) {
        width: calc(100vw);
    }
`;
