import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AppColors } from '../StyledComponents/Graphics';
import {
  StyledLabel
} from '../StyledComponents/UserFormStyledComponents';
import { CloseIcon } from '../Icons/Icons';
import { Line } from '../StyledComponents/AppStyledComponents';

const DropdownWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const DropdownButton = styled.div`
    width: 100%;
    cursor: pointer;
    border: 1px solid;
    border-color: ${AppColors.MRNAVY60};
    border-radius: 10px;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    flex-wrap: wrap;
    max-height: 80px;
    overflow: auto;
`;

const DropdownMenu = styled.div`
    background: ${AppColors.MRWHITE};
    color: ${AppColors.MRBLACK};
    border: 1px solid ${AppColors.MRGREY70};
    border-radius: 10px;
    box-sizing: border-box;
    max-height: ${props => `${props.maxHeight}px` || '200px'};
    overflow: auto;
    position: absolute;
    width: 100%;
    z-index: 999;
`;

const DropdownItem = styled.div`
    padding: 5px 10px;

    &:hover {
        background: ${AppColors.MRBLUE};
        color: ${AppColors.MRBLACK};
    }
`;

const RemoveButton = styled.button`
    background: ${AppColors.MRGREY70};
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px;
    color: ${AppColors.MRNAVY20};
    /* display: inline-block; */
    white-space: pre-wrap;
    font-size: 14px;
    height: 24px;
    padding: 4px;
    overflow: hidden;
    max-width: 95%;
    height: 100%;
    width: fit-content;

    &:hover {
      background-color: ${AppColors.MRRED};
      border: 1px solid;
      padding: 3px; 
    }
`;

const SearchBox = styled.input`
    width: 100%;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid;
    border-color: ${AppColors.MRGREY70};
    border-radius: 10px;
    padding: 10px 12px;
    font-size: 16px;
    background-color: ${AppColors.MRWHITE};
    color: ${AppColors.MRNAVY};

    /* &:focus {
      outline-width: 0;
    } */
`;

const MultiSelectDropdown = ({
    dropdownContainerRef,
    handleChange,
    id,
    label,
    options,
    value,
}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const wrapperRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    useEffect(() => {
        setSelectedOptions(options?.filter((option) => value?.includes(option.value)));
    }, [value])

    const handleSelect = (option) => {
      setSelectedOptions(prev => {
        const newState = [...prev, option];
        handleChange({ id, selectedOptions: newState });

        return newState;
      });
        setSearchText('');
    };

    const handleRemove = (e, removed) => {
        e.stopPropagation();
        setSelectedOptions(prev => {
          const newState = prev.filter(option => option.value !== removed.value);
          handleChange({ id, selectedOptions: newState });

          return newState;
        });
    };

    const toggleDropdown = () => {
        setDropdownOpen(prev => !prev);
    };

    const handleSearch = event => {
        setSearchText(event.target.value);
    };

    const filteredOptions = options?.filter(option => {
        return option?.label?.toLowerCase().includes(searchText?.toLowerCase()) &&
            !selectedOptions?.some(selected => selected.value === option.value);
    });

    useEffect(() => {
        setSelectedOptions(options?.filter((option) => value?.includes(option?.value)) || []);
    }, [options])
    return (
        <DropdownWrapper ref={wrapperRef}>
            {label ?
                <StyledLabel>
                    {label}:
                </StyledLabel> :
                null
            }
            {selectedOptions?.length ?
                <DropdownButton>
                    {selectedOptions?.map(option => (
                        <RemoveButton key={option.value} onClick={(e) => handleRemove(e, option)}>
                            <CloseIcon style={{ marginRight: '2px' }} />    
                            {option.label}
                        </RemoveButton>
                    ))}
                </DropdownButton> :
                null
            }
            <SearchBox
                ref={dropdownRef}
                onClick={toggleDropdown}
                type="text"
                value={searchText}
                onChange={handleSearch}
                placeholder="Search..."
            />
            {dropdownOpen && (
                <DropdownMenu maxHeight={dropdownContainerRef ? dropdownContainerRef?.current?.getBoundingClientRect().bottom - dropdownRef?.current?.getBoundingClientRect().bottom : null}>
                    {filteredOptions?.map(option => (
                        option.label === '-' ? (
                            <Line key={option.value} />
                        ) : (
                            <DropdownItem key={option.value} onClick={() => handleSelect(option)}>
                                {option.label}
                            </DropdownItem>
                        )
                    ))}
                </DropdownMenu>
            )}
        </DropdownWrapper>
    );
};

export default MultiSelectDropdown;
