import { createContext, useEffect, useState } from "react";
import supabase from "../services/supabaseClient";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [session, setSession] = useState(null);
    const [user, setUser] = useState(null);
    
    useEffect(() => {
        const getUser = async () => {
            const { data, error } = await supabase.auth.getSession();
            const session = data.session;
            const user = session?.user || undefined; 
            if (!user) {
                return;
            }
            if (user?.role === 'authenticated') {
                setUser(user);
                setSession(session);
            } else {
                console.log(error);
            }
            return;
        };
        getUser();
    }, []);

    const authState = {
        session,
        setSession,
        setUser,
        user,
    };

    return (
        <AuthContext.Provider value={authState}>
            {children}
        </AuthContext.Provider>
    );
};
