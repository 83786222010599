import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { HomeIcon } from "../../Icons/Icons";
import { BlackTextGlassLogo } from "../../Logos/Logos";
import ProfileMenu from "./components/ProfileMenu";
import { HeaderContainer, MenuButton } from "./styledComponents";
import { StateContext } from "../../../Controllers/contexts/StateContext";
import { DEFAULT_INPUTS } from "../../constants";


const Header = () => {
    const {
      setSideBarOpen,
      sideBarOpen,
      setInputs,
      setSelectedWatchlist,
      setSelectedPortfolio,
      setShowHistory,
      setStartAnalysis,
    } = useContext(StateContext);

    const navigate = useNavigate();

    // const toggleMenu = () => {
    //     setSideBarOpen(prevState => !prevState);
    // }

    const goHome = () => {
      navigate('/');
      setStartAnalysis(false);
      setInputs(DEFAULT_INPUTS);
      setSelectedWatchlist(null);
      setSelectedPortfolio(null);
      setShowHistory(false);
    }

    return (
      <HeaderContainer>
        {/* <div></div> */}
        {/* <MenuButton clicked={sideBarOpen} onClick={toggleMenu}> */}
        <MenuButton onClick={goHome}>
          <HomeIcon />
        </MenuButton>
        <BlackTextGlassLogo />
        <ProfileMenu />
      </HeaderContainer>
    );
  };
  
  export default Header;