import styled from "styled-components";

export const HeaderContainer = styled.header`
     display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    width: 100vw;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 10;
`;

export const MenuButton = styled.div`
    align-items: center;
    display: flex;
    height: 54px;
    justify-content: center;
    width: 56px;
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
    cursor: pointer;

    box-shadow: ${({ clicked }) => clicked ? '0 0px 2px rgba(0, 0, 0, 0.2)' : null};

    &:hover {
        box-shadow: 0 0px 2px rgba(0, 0, 0, 0.2);
    }

    &:active {
        box-shadow: 0 0px 2px rgba(0, 0, 0, 0.2);
    }
`;

export const MenuContainer = styled.div`
    display: flex;
    align-items: center;
`;
